import { Injectable } from '@angular/core';
import { CorrectionsHandler } from '@app-store/corrections/handler/corrections.handler';
import { EscalationQueue } from '@shared/enums/common-data/escalation-queue.enum';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeEscalateService {

  constructor(private correctionsHandler: CorrectionsHandler) { }

  isScalationQueue(): Observable<boolean> {
    return this.correctionsHandler.getCorrectionsCorrectedDetail$.pipe(
      map(correctedDetail =>
        this.findEscalationQueue(correctedDetail?.requestInformation?.reviewQueue)
      )
    );
  }

  findEscalationQueue(correctionQueue?: string): boolean {
    if (!correctionQueue) return false;
    return Object.values(EscalationQueue).includes(correctionQueue as any);
  }

}
