import { Injectable } from '@angular/core';
import { BillCorrectionApiService } from '@xpo-ltl-2.0/sdk-billcorrection';
import { User } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(private billCorrectionApiService: BillCorrectionApiService) { }

  getLoggedUser(): Observable<User> {
    return this.billCorrectionApiService.loggedInUser().pipe(
      catchError((error: any) => {
        console.error('UserApiService', 'getUser', error);
        return throwError(error);
      })
    );
  }
}
