import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ScreenEnum } from '@shared/enums/common-data/screen.enum';
import { ScreenActions } from '../actions/screen.actions';
import { ScreenSelector } from '../selectors/screen.selectors';

@Injectable()
export class ScreenHandler {
  // ACTIONS *******************************************************************************************

  // SELECTORS *****************************************************************************************
  getCurrentScreen$ = this.store$.pipe(select(ScreenSelector.getCurrentScreen));
  getPreviousScreen$ = this.store$.pipe(select(ScreenSelector.getPreviousScreen));

  constructor(private store$: Store) { }

  setCurrentScreen(currentScreen: ScreenEnum): void {
    this.store$.dispatch(ScreenActions.setCurrentScreen({ currentScreen }));
  }
}
