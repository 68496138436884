import { BillClassCd } from "@xpo-ltl/sdk-common";

export enum BillClassTextEnum {
  normalMvmt = 'NORM',
  masterShpmt = 'MSTR',
  masterSegment = 'MSEG',
  splitSegment = 'PTLT',
  partialSegment = 'PSEG',
  claimsOvrgRptgBill = 'OREP',
  generalClaimsBusShpmt = 'GCBZ',
  astrayFrtSegment = 'MOVR',
  coBusShpmt = 'COBZ',
  expedite = 'EXPD',
}

export enum BillClassEnum {
  accessorialsOnly = BillClassCd.ACCESSORIAL_ONLY,
  astrayFrtSegment = BillClassCd.ASTRAY_FRT_SEGMENT,
  claimsOvrgRptgBill = BillClassCd.CLAIMS_OVRG_RPTG_BILL,
  coBusShpmt = BillClassCd.CO_BUS_SHPMT,
  expedite = BillClassCd.EXPEDITE,
  generalClaimsBusShpmt = BillClassCd.GENERAL_CLAIMS_BUS_SHPMT,
  masterSegment = BillClassCd.MASTER_SEGMENT,
  masterShpmt = BillClassCd.MASTER_SHPMT,
  normalMvmt = BillClassCd.NORMAL_MVMT,
  notApplicable = BillClassCd.NOT_APPLICABLE,
  partialSegment = BillClassCd.PARTIAL_SEGMENT,
  splitSegment = BillClassCd.SPLIT_SEGMENT,
}

export enum BillClassDescriptionEnum {
  masterShpmt = 'MASTER BILL: POOL SHPMNT MOVING ON PROS',
  masterSegment = 'SHPT SEG COV BY M/',
  splitSegment = 'PART LOT SHIPMENT MOVING ON PROS',
  partialSegment = 'PART LOT SHPT-SEGMENT PRO',
  astrayFrtSegment = 'TO MOVE FRT SHORT ON THIS PRO. MOVMENT PRO',
  coBusShpmt = 'DH CO BIZ',
  expedite = 'EXPD-DO NOT DELIVER ON THIS BILL'
}
