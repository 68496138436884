export enum CorrectionsMiscellaneousFormEnum {
    type = 'type',
    instructions = 'instructions',
};

export enum CorrectionsMiscellaneousFormLabelEnum {
    type = 'rate type',
    instructions = 'rating instructions',
    warning = 'Do not use the Rate Type Section to request Cancel Charges or Forward Charges Correction.'
};

export enum CorrectionsMiscellaneousFormPlaceholderEnum {
    instructions = 'ENTER MORE DETAILS',
};

export enum CorrectionsMiscellaneousFormIdEnum {
    typeFormField = 'miscellaneousTypeFormField',
    typeField = 'miscellaneousType',
    instructions = 'miscellaneousInstructions',
};

export enum CorrectionsMiscellaneousFormTitlesEnum {
    title = 'Miscellaneous Rate Types'
};

export enum CorrectionsMiscellaneousFormTextEnum {
  title = 'Miscellaneous Rate Types',
  message = `
    <p> Use the Miscellaneous Rate Type section to re-rate the bill using one of the following rate types:
      <br>1 - Lineal Feet 
      <br>2 - Pallet rate
      <br>3 - Piece rate
      <br>4 - Spot quote
      <br>5 - Flat rate
      <br>6 - Commodity class rate
      <br>7 - Other rate
      <br><br>
      NOTE:
      <i> Do NOT use the Rate Type section to request a Cancel Charges or Forward Charges. 
        <br> To request a Cancel Charges, select the Common Corrections tab, and then the Cancel Charges tab.<br>
      </i><br>
    </p>`,
  messageAuditor = `
    <p>
      For Rate Type corrections, change the Rate and Weight, 
      or the As Weight for the commodity before approving the request.<br><br>
      <i> Tip: If you approve the correction without 
      changing the rate or weight, the change will fail and the request will 
      be pending with an error.
      </i>
    </p>`
};

export enum CorrectionsMiscellaneousFormRulesEnum {
    instructionsMaxLength = 450
};
