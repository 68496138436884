import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { UserApiMapperService } from "@shared/mappers/common/user/user-api-mapper.service";
import { ErrorModel } from "@shared/models/shared/error.model";
import { UserModel } from "@shared/models/shared/user.model";
import { UserApiService } from "@shared/services/apis/user/user-api.service";
import { User } from "@xpo-ltl/sdk-common";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { UserActions } from "../actions/user.actions";

@Injectable()
export class UserEffects {
    getLoggedUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.getUser),
            switchMap(() => {
                return this.userApiService.getLoggedUser().pipe(
                    map((user: User) => this.userApiMapperService.getUser(user)),
                    map((user: UserModel) => UserActions.getUserSuccess({ user })),
                    catchError((errors: ErrorModel[]) =>
                        of(UserActions.getUserFail({ errors }))
                    )
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private userApiService: UserApiService,
        private userApiMapperService: UserApiMapperService
    ) { }
}
