import { createSelector } from '@ngrx/store';
import { ScreenReducer, ScreenStateModel } from '../reducer/screen.reducer';

const selector = createSelector(ScreenReducer.screenFeatureSelector, (state) => state);

const getCurrentScreen = createSelector(selector, (state: ScreenStateModel) => state.currentScreen);
const getPreviousScreen = createSelector(selector, (state: ScreenStateModel) => state.previousScreen);

export const ScreenSelector = {
  getCurrentScreen,
  getPreviousScreen
};
