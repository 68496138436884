import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { CorrectionSideNavSectionEnum } from '@shared/enums/corrections/corrections-sidenav.enum';
import { SideNavActions } from '../actions/side-nav.actions';

export const sideNavFeatureKey = 'sideNavKey';
const sideNavFeatureSelector = createFeatureSelector<SideNavStateModel>(sideNavFeatureKey);

export interface SideNavStateModel {
  sidenavOpenSection: CorrectionSideNavSectionEnum | undefined;
}

const initialState: SideNavStateModel = {
  sidenavOpenSection: undefined,
};

const reducer = createReducer(
  initialState,
  on(SideNavActions.clearSidenavOpenSection, (state): SideNavStateModel => {
    return {
      ...state,
      sidenavOpenSection: undefined,
    };
  }),
  on(SideNavActions.setSidenavOpenSection, (state, { sidenavOpenSection }): SideNavStateModel => {
    return {
      ...state,
      sidenavOpenSection,
    };
  })
);

export const SideNavReducer = {
  reducer,
  sideNavFeatureKey,
  sideNavFeatureSelector,
  initialState,
};
