import { Injectable } from '@angular/core';
import { CreditStatusEnum } from '@shared/enums/common-data/credit-status.enum';
import { CorrectionsCreditStatusRespModel } from '@shared/models/corrections/corrections-detail.model';
import { GetCreditStatusPath, GetCreditStatusResp } from '@xpo-ltl-2.0/sdk-customer';
import { ShipmentCreditStatusCd } from '@xpo-ltl/sdk-common';

@Injectable()
export class CorrectionsCreditStatusMapperService {

  constructor() { }

  getRequest(customerNumber: number): GetCreditStatusPath {
    const request = new GetCreditStatusPath();
    request.customerId = `${customerNumber}`;
    return request;
  }

  getMappedData(apiResponse: GetCreditStatusResp): CorrectionsCreditStatusRespModel {
    const response = new CorrectionsCreditStatusRespModel();
    response.creditStatus = apiResponse.creditStatus === CreditStatusEnum.credit ? ShipmentCreditStatusCd.CREDIT : ShipmentCreditStatusCd.NON_CREDIT;

    return response;
  }
}
