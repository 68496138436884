export enum CorrectionsFormFooterLabelsEnum {
    description = 'By submitting this correction, I confirm that all required documents will be placed in image and that the correction is valid.',
    submitBtn = 'SUBMIT',
    previewAutoRateBtn = 'PREVIEW AUTO RATE',
    previewManualRateBtn = 'PREVIEW MANUAL RATE',
};

export enum CorrectionsFormFooterIdsLabelsEnum {
    submitBtn = 'submitFooter',
    previewAutoRateBtn = 'previewAutoRateFooter',
    previewManualRateBtn = 'previewManualRateFooter',
};

export enum CorrectionsFormFooterTooltipEnum {
    previewAutoRateTitle = 'Preview Auto-Rated Changes button',
    previewAutoRateAuditorTitle = 'Preview Auto-Rated Changes button (for auditors only)',
    previewManualRateTitle = 'Preview Manual-Rated Changes button',
    previewAutoRateMessage = `
    <div>
      <p>
        For most types of bills, this button displays a preview screen 
        that shows the effect of corrections on the bill's total charges 
        before you submit the request. It's not necessary to use the button, 
        but it provides a handy way to tell customers the <b>estimated</b> corrected 
        charges on their bill, for example, <b>pending auditor approval</b>.
        <br><br>
				<i>
          TIP: This button does not change anything on your request. For example, 
          dollar amounts for new accessorials will appear on the preview screen but 
          they won't appear on your request screen after you use the button. Appropriate 
          charges will be applied when your request is processed.
        </i> 
        <br><br>
				<i>
          TIP: The preview screen may not show the deletion of some accessorials, 
          such as a fuel surcharge, because auto-rating automatically adds some 
          accessorials to bills. Appropriate charges will be removed when your 
          request is processed
        </i>
        <br><br>
				
				Here's what happens when you click the Preview button.<br><br>
				
				Changes to bill information that can be auto-rated appear in freight bill 
				copy format on the preview screen. (If the bill can't be auto-rated, such as a 
				Both bill, a system message appears instead of the preview screen.)<br><br> 
				
				If there are changes to the following types of information, they are listed 
				below the auto-rated results on the preview screen because they can't be auto-rated: 
				commodity rates or charges; accessorial rates or charges; and discount percentage
				or amount. (To see the effect of changing the discount amount, remove the percentage 
				from the request).<br><br> 
				
				To see the effect of overriding the auto-rated results with the manually rated changes,
				click the Apply Manual-Rated Changes button. The overrides appear in red text 
				on the preview screen. <br><br>
				
				To return to the request screen, click the Go Back button. Information on the preview 
				screen does not change anything on your request. <br><br>
      </p>
    </div>`,
    previewAutoRateAuditorMessage = `
    <table width="100%">
      <tr>
        <td class="stdDisplayText">
          <p>
            <b>
            Using Preview Buttons and Selecting the Rate Option
            </b><br>(for auditors only)
          </p>
          <p>
            There are two Preview buttons, each with their own purpose and limitations: 
            the Preview Auto-Rated Changes button and the Preview Manual-Rated Changes 
            button. They allow you to see new total charges for the bill in freight 
            bill copy format before you approve the request. Each Preview button may 
            show different results for the same request. This is to be expected because 
            of the different way the two buttons work, as explained below.
          </p>
          <p>
            You can change information on a request and preview the result as often 
            as you wish before approving a request. Sometimes, but not always, the 
            Preview button that shows desirable results is the same rating method 
            you should select before approving the bill, either Auto-Rate or Manual-Rate. 
            See below for more information.
          </p>
          <table width="100%">
            <tr bgcolor="#CCCCCC"> 
              <td valign="top" height="5" colspan="3"><font size="1"></font></td>
            </tr>
            <tr> 
              <td class="stdDisplayText" >
                <p><b><i>Preview Auto-Rated Changes Button</i></b></p>
                <p>
                  AT-A-GLANCE. Works for auto-rateable bills only. Shows the result 
                  of auto-rating the bill. Also provides the ability to see the effect 
                  of any manually rated changes and to apply them to the request, 
                  if desired.
                </p>
                <p>
                  THE DETAILS. When you click the Preview Auto-Rated Changes button, 
                  the request is processed by auto-rating. The results appear in freight 
                  bill copy format on the preview screen. The rate aids applicable 
                  to the PRO before and after the correction appear at the bottom 
                  of the screen. Any manually rated changes on the request are listed 
                  below the auto-rated results. By clicking the Apply Manual-Rated 
                  Changes button, you can override the auto-rated changes on the request 
                  with the manually rated changes and see the new total charges on 
                  the preview screen. If you like what you see, you can click the 
                  Accept Manual-Rated Changes button to apply the changes to the request. 
                  This is a shortcut way to update the request with new total charges. 
                  If you do this, you must select the Manual-Rate option before approving 
                  the request. If there were no manually rated changes listed on the 
                  preview screen or you don't apply the manually rated overrides to 
                  the request, you must select the Auto-Rate option before approving 
                  the request.
                </p>
                <p><b><i>Selecting the Rating Option When You Use the Preview Auto-Rated Changes Button</i></b></p>
                <p>
                  If you click the Preview Auto-Rated Changes button&#133;, and there 
                  are no manually rated changes listed below the auto-rated results&#133;, 
                  and you like what you see on the preview screen&#133;, select the 
                  Auto-Rate option before approving the request.
                </p>
                <p>&#151; OR &#151; </p>
                <p>
                  If you click the Preview Auto-Rated Changes button&#133;, and manually 
                  rated changes are listed below the auto-rated results and you click 
                  the Apply Manual-Rated Changes button&#133;, and you like what you 
                  see on the preview screen and you click the Accept Manual-Rated 
                  Changes button&#133;, select the Manual-Rate option before approving 
                  the request.
                </p>
                <p><i><b>Tips for the Preview Auto-Rated Changes Button</b></i></p>
                <p>
                  NON-AUTO-RATEABLE BILLS. Does not work for bills that cannot be 
                  auto-rated, such as Both bills. A system message appears instead 
                  of the preview screen.
                </p>
                <p>
                  MANUALLY RATED BILLS. Generally, do not use the Preview Auto-Rated 
                  Changes button for a bill that has been manually rated, as noted 
                  in red at the bottom of the screen, because it could remove previously 
                  entered manually rated information from the bill. If you accidentally 
                  click the button, click the Go Back button on the preview screen.
                </p>
                <p>
                  DELETIONS: To preview the effect of deleting an accessorial, such 
                  as a fuel surcharge, or deleting a weight adjustment, start with 
                  the Preview Auto-Rated Changes button. If the item is deleted, select 
                  the Auto-Rate option before approving the request. But if the item 
                  is not deleted, it means auto-rating automatically added it to the 
                  bill, even though it is marked for deletion. In this situation, 
                  use the Preview Manual-Rated Changes button to see the effect of 
                  the deletion and select the Manual-Rate option before approving 
                  the request. To preview the effect of deleting a hazmat accessorial 
                  using the Preview Auto-Rated Changes button, the hazmat indicator 
                  on the commodity must be No. To preview this deletion using the 
                  Preview Manual-Rated Changes button, only the hazmat accessorial 
                  needs to be deleted.
                </p>
                <p>
                  ACCESSORIALS. To override the auto-rated amount for an accessorial, 
                  enter an amount on the request. To override a percentage-based accessorial, 
                  enter M for the rate and the amount for the accessorial on the request. 
                  If the debtor is exempt from paying an accessorial, the word DELETED 
                  appears in place of the charge on the preview screen.
                </p>
                <p>
                  DISCOUNT. To see the result of changing the discount amount, remove 
                  the discount percentage from the request.
                </p>
                <p>
                  GUARANTEED INDICATOR. Shows guaranteed accessorial charges when 
                  the Guaranteed Indicator on the request is Yes, even if there is no 
                  GUR or GFR accessorial on the request. In this situation, you must 
                  add the appropriate guaranteed accessorial code and description 
                  before approving the request.
                </p>
                <p>
                  SHIPPER/CONSIGNEE NAME AND ADDRESS CHANGE (WITHOUT A MAD CODE): 
                  The preview screen may show different results than the final correction. 
                  If the system can match a MAD code to the new name and address after 
                  the request is approved, different rates may be applied to the bill.
                </p>
                <p>
                  INFORMATIONAL RATE AIDS: If you see "unexpected results" when you use the 
                  Preview Auto-Rated Changes button, it's a cue to read the rate aids at the bottom
                  of the preview screen. They may indicate that a specific charge is waived during 
                  auto-rating, which would explain the "unexpected results." A rate aid line with 
                  the letter I ("eye") at the beginning means it's "for information only"; 
                  auto-rating does not use it. So, to apply that information to the bill, you 
                  must enter it on the request and select the Manual Rate option before approving the request.
                </p>
                <p>
                  TARIFF CODE. When the tariff code is the only change on a request, 
                  the Apply New Tariff Rates button is available on the preview screen 
                  instead of the Apply Manual-Rated Changes button.
                </p>
                <p>
                  REQUESTER SCREENS: The Preview Auto-Rated Changes button is also 
                  available to requesters. But it only allows them to preview auto-rated 
                  changes and see the effect of manually rated overrides on the auto-rated 
                  results. It does not give them the ability to apply the manually 
                  rated overrides to the request.
                </p>
              </td>
              <td valign="top" height="1206" bgcolor="#CCCCCC" width="2">&nbsp;</td>
              <td class="stdDisplayText" valign="top">
                <p><b><i>Preview Manual-Rated Changes Button</i></b></p>
                <p>
                  AT-A-GLANCE. Works for all types of bills. Shows the result of 
                  re-calculating total charges using rates and charges on the request. 
                </p>
                <p>
                  THE DETAILS. When you click the Preview Manual-Rated Changes button, 
                  the system re-calculates total charges for the bill based on rates 
                  and charges on the request. The results appear in freight bill copy 
                  format on the preview screen. When you return to the request screen, 
                  the result of the recalculation also appears on the request. For 
                  the request to be processed with the changes you see on the preview 
                  screen, you must select the Manual-Rate option before approving 
                  the request. 
                </p>
                <p><i><b>Selecting the Rating Option When You Use the Preview Manual-Rated Changes Button</b></i></p>
                <p>
                  If you click the Manual-Rated Changes button&#133;, and you like 
                  what you see on the preview screen&#133;, select the Manual-Rate 
                  option before approving the request.
                </p>
                <p><i><b>Tips for the Preview Manual-Rated Changes Button</b></i></p>
                <p>
                  DELETIONS: To preview the effect of deleting an accessorial, such 
                  as a fuel surcharge, or deleting a weight adjustment, start with 
                  the Preview Auto-Rated Changes button. If the item is deleted, select 
                  the Auto-Rated option before approving the request. But if the item 
                  is not deleted, it means auto-rating automatically added it to the 
                  bill, even though it was marked for deletion. In this situation, 
                  use the Preview Manual-Rated Changes button to see the effect of 
                  the deletion and select the Manual-Rate option before approving 
                  the request. To preview the effect of deleting a hazmat accessorial 
                  using the Preview Auto-Rated Changes button, the hazmat indicator 
                  on the commodity must be No. To preview this deletion using the 
                  Preview Manual-Rated Changes button, only the hazmat accessorial 
                  needs to be deleted.</p>
                <p>
                  BOTH BILL. After previewing manual-rated changes and before approving 
                  the request with the Manual-Rate option, adjust the Prepaid and 
                  Collect Amounts to match the new total charges. </p>
                <p>
                  DEBTOR CHANGES: The Preview Manual-Rated Changes button does not 
                  show changes to charges when the debtor or the bill to is changed. 
                  In these situations, use the Preview Auto-Rated Changes button. 
                </p>
                <p>
                  DISCOUNT. To see the result of changing the discount amount, remove 
                  the discount percentage from the request.</p>
                <p>
                  REQUESTER SCREENS: The Preview Manual-Rated Changes button is not available 
                  to requesters.
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    `,
    previewManualRateMessage = `For most types of bills, this button displays a preview screen that shows the effect of corrections on the bill's total charges before you submit the request. It's not necessary to use the button, but it provides a handy way to tell customers the estimated corrected charges on their bill, for example, pending auditor approval.
    <br>
    <br>
    TIP: This button does not change anything on your request. For example, dollar amounts for new accessorials will appear on the preview screen but they won't appear on your request screen after you use the button. Appropriate charges will be applied when your request is processed.
    <br>
    <br>
    TIP: The preview screen may not show the deletion of some accessorials, such as a fuel surcharge, because auto-rating automatically adds some accessorials to bills. Appropriate charges will be removed when your request is processed
    <br>
    <br>
    Here's what happens when you click the Preview button.
    <br>
    <br>
    Changes to bill information that can be auto-rated appear in freight bill copy format on the preview screen. (If the bill can't be auto-rated, such as a Both bill, a system message appears instead of the preview screen.)
    <br>
    <br>
    If there are changes to the following types of information, they are listed below the auto-rated results on the preview screen because they can't be auto-rated: commodity rates or charges; accessorial rates or charges; and discount percentage or amount. (To see the effect of changing the discount amount, remove the percentage from the request).
    <br>
    <br>
    To see the effect of overriding the auto-rated results with the manually rated changes, click the Apply Manual-Rated Changes button. The overrides appear in red text on the preview screen.
    <br>
    <br>
    To return to the request screen, click the Go Back button. Information on the preview screen does not change anything on your request.`,
};
