import { Injectable } from '@angular/core';
import { CorrectionsRevenuesModel } from '@shared/models/corrections/corrections-form/revenues/corrections-revenues.model';
import { GetAutoRatesPreviewResp, GetManualRatesPreviewResp } from '@xpo-ltl-2.0/sdk-billcorrection';

@Injectable()
export class RevenuesCorrectedMapperService {

  constructor() { }

  getMappedDataFromPreviewAutoRate(response: GetAutoRatesPreviewResp): CorrectionsRevenuesModel {
    return {
      ctsRevenue: response?.ratingResponse?.ctsRevenueAmt,
      totalRevenue: response?.ratingTotal?.totalAccessorialAmt,
      totalWeight: response?.ratingTotal?.totalWeight,
    }
  }

  getMappedDataFromPreviewManualRate(response: GetManualRatesPreviewResp): CorrectionsRevenuesModel {
    return {
      ctsRevenue: response?.manualRatingPro?.ratingInformation?.ctsRevenueAmount,
      totalRevenue: response?.manualRatingPro?.ratingInformation?.totalAccessorialAmount,
      totalWeight: response?.manualRatingPro?.ratingInformation?.reducedWeight,
    }
  }
}
