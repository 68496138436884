import { ActionReducerMap } from '@ngrx/store';
import { userFeatureKey, UserReducer, UserStateModel } from './user/reducer/user.reducer';

export interface AppState {
    [userFeatureKey]: UserStateModel;
}

export const appReducers: ActionReducerMap<AppState> = {
    [userFeatureKey]: UserReducer.reducer
};
