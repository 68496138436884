import { AbstractControl } from "@angular/forms";
import { CorrectionsBillToFormEnum } from "@shared/enums/corrections/corrections-form/billto/corrections-billto-form.enum";
import { CorrectionsCustomerFormModel, CorrectionsCustomerModel } from "../customer/customer-form.model";

export class CorrectionsBilltoFormModel implements CorrectionsCustomerFormModel {
  customerNumber: AbstractControl;
  madCode: AbstractControl;
  nameOne: AbstractControl;
  nameTwo: AbstractControl;
  address: AbstractControl;
  city: AbstractControl;
  state: AbstractControl;
  zipCode: AbstractControl;
  zipCodeComplementation: AbstractControl;
  country: AbstractControl;
  chrgsResp: AbstractControl;
  deleteBillTo: AbstractControl;
};

export class CorrectionsBillToModel implements CorrectionsCustomerModel {
  [CorrectionsBillToFormEnum.deleteBillTo]: boolean | undefined;
  [CorrectionsBillToFormEnum.customerNumber]: number;
  [CorrectionsBillToFormEnum.madCode]: string;
  [CorrectionsBillToFormEnum.nameOne]: string;
  [CorrectionsBillToFormEnum.nameTwo]: string;
  [CorrectionsBillToFormEnum.address]: string;
  [CorrectionsBillToFormEnum.city]: string;
  [CorrectionsBillToFormEnum.chrgsResp]: string;
  [CorrectionsBillToFormEnum.state]: string;
  [CorrectionsBillToFormEnum.zipCode]: string;
  [CorrectionsBillToFormEnum.zipCodeComplementation]: string;
  [CorrectionsBillToFormEnum.country]: string;
};
