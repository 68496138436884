import { Injectable } from '@angular/core';
import { CorrectionShipment } from '@xpo-ltl-2.0/sdk-billcorrection';
import { DeliveryQualifierCd } from '@xpo-ltl/sdk-common';

@Injectable({
  providedIn: 'root'
})
export class DeliveryStatusMapperService {

  constructor() { }

  isFinalDeliveryStatus(shipment: CorrectionShipment): boolean {
    return shipment?.deliveryQualifierCd === DeliveryQualifierCd.FINAL;
  }
}
