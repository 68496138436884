import { Injectable } from '@angular/core';
import { BillClassEnum } from '@shared/enums/common-data/bill-class.enum';
import { BillClassCd } from '@xpo-ltl/sdk-common';

@Injectable({
  providedIn: 'root'
})
export class NonRevenuesMapperService {

  constructor() { }

  getBillClassToApi(billClass: BillClassEnum): BillClassCd {
    const mappedBillClass = Object.values(BillClassCd).find(value => value === billClass as any) as BillClassCd;
    
    if (!mappedBillClass) {
      console.error('the billCass for mapping to an api is not part of the mapped BillClassCd', billClass)
    }

    return mappedBillClass;
  }

  getBillClassFromApi(billClass: BillClassCd | string): BillClassEnum {
    const mappedBillClass = Object.values(BillClassEnum).find(value => value === billClass) as BillClassEnum

    if (!mappedBillClass) {
      console.error('the returned billCass is not part of the mapped billClasses', billClass)
    }

    return mappedBillClass;
  }

}
