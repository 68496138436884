export enum CorrectionsCommoditiesFormEnum {
    id = 'id',
    sequenceNbr = 'sequenceNbr',
    delete = 'delete',
    pieces = 'pieces',
    pkg = 'pkg',
    hazmat = 'hazmat',
    description = 'description',
    nmfc = 'nmfc',
    class = 'class',
    weight = 'weight',
    rate = 'rate',
    minimumChargeInd = 'minimumChargeInd',
    amount = 'amount',
    markedAction = 'markedAction',
};

export enum CorrectionsCommoditiesFormLabelEnum {
    delete = 'delete',
    pieces = 'pieces',
    pkg = 'pkg',
    hazmat = 'hazmat',
    description = 'description',
    nmfc = 'nmfc',
    class = 'class',
    weight = 'weight',
    rate = 'rate',
    amount = 'amount',
};

export enum CorrectionsCommoditiesFormIdsEnum {
    delete = 'deleteCommodity',
    pieces = 'piecesCommodity',
    pkg = 'pkgCommodity',
    hazmat = 'hazmatCommodity',
    description = 'descriptionCommodity',
    nmfc = 'nmfcCommodity',
    class = 'classCommodity',
    weight = 'weightCommodity',
    rate = 'rateCommodity',
    amount = 'amountCommodity',
};

export enum CorrectionsCommoditiesFormTitlesEnum {
    title = 'commodities'
};

export enum CorrectionsCommoditiesFormRulesEnum {
    maxQuantityOfCommoditiesRow = 30,
}
