import { Injectable } from '@angular/core';
import { CorrectionsPreviewRatesModel } from '@shared/models/corrections/preview-rates/corrections-preview-rates.model';
import { CorrectionsFormValueModel } from '@shared/models/corrections/corrections-form/corrections-form.model';
import { CorrectionsCodAmountModel } from '@shared/models/corrections/corrections-form/cod-amount/corrections-cod-amount.model';
import { CorrectionsDiscountAmountModel } from '@shared/models/corrections/corrections-form/discount-amount/corrections-discount-amount.model';
import { CorrectionsWeightModel, CorrectionsWeightTypeModel } from '@shared/models/corrections/corrections-form/weight/corrections-weight.model';
import { CorrectionsAccessorialsModel } from '@shared/models/corrections/corrections-form/accessorials/corrections-accessorials.model';
import { CorrectionCommoditiesModel } from '@shared/models/corrections/corrections-form/commodities/corrections-commodities.model';
import { CorrectionsWeightFormTypeEnum } from '@shared/enums/corrections/corrections-form/weight/corrections-weight-form.enum';
import { CorrectionsRevenuesModel } from '@shared/models/corrections/corrections-form/revenues/corrections-revenues.model';

@Injectable()
export class CorrectionsPreviewtoRequestMetadataMapperService {

  getRequestFromPreview(preview: CorrectionsPreviewRatesModel): CorrectionsFormValueModel {
    const formMapped: CorrectionsFormValueModel = {
      ...new CorrectionsFormValueModel(),
      weight: this.getWeight(preview),
      revenues: this.getRevenues(preview),
      codAmount: this.getCodAmount(preview),
      discountAmount: this.getDiscountAmount(preview),
    };
    return formMapped;
  }

  getRevenues(preview: CorrectionsPreviewRatesModel): CorrectionsRevenuesModel {
    return {
      ctsRevenue: preview.revenues.totalRevenue,
      totalRevenue: preview.totals.totalCharges,
      totalWeight: preview.totals.totalWeight
    }
  }

  getWeight(preview: CorrectionsPreviewRatesModel): CorrectionsWeightModel {
    const weight = {...new CorrectionsWeightModel()};

    if (preview.weightLine.asWeight) {
      weight.asWeight = {
        sequenceNbr: '',
        description: preview.weightLine.asWeight?.description || '',
        amount: preview.weightLine.asWeight?.amount || 0,
        rate: preview.weightLine.asWeight?.rate || 0,
        quantity: preview.weightLine.asWeight?.quantity || 0,
        type: CorrectionsWeightFormTypeEnum.asWeight,
        minimumChargeInd: preview.weightLine.asWeight.minimumChargeInd
      };
    }

    if (preview.weightLine.deficitWeight) {
      weight.deficitWeight = {
        sequenceNbr: '',
        description: preview.weightLine.deficitWeight?.description || '',
        amount: preview.weightLine.deficitWeight?.amount || 0,
        rate: preview.weightLine.deficitWeight?.rate || 0,
        quantity: preview.weightLine.deficitWeight?.quantity || 0,
        type: CorrectionsWeightFormTypeEnum.deficitWeight,
        minimumChargeInd: preview.weightLine.deficitWeight.minimumChargeInd
      };
    }
    
    return weight;
  }

  getCodAmount(preview: CorrectionsPreviewRatesModel): CorrectionsCodAmountModel {
    if (!preview.codAmount?.amount && !preview.codAmount?.description) return new CorrectionsCodAmountModel();
    return {
      delete: false,
      sequenceNbr: preview.codAmount.sequenceNbr,
      amount: preview.codAmount.amount,
      description: preview.codAmount.description
    };
  }

  getDiscountAmount(preview: CorrectionsPreviewRatesModel): CorrectionsDiscountAmountModel {
    if (!preview.discounts?.amount && !preview.discounts?.description) return new CorrectionsDiscountAmountModel();
    return {
      delete: false,
      sequenceNbr: preview.discounts.sequenceNbr,
      description: preview.discounts.description,
      amount: preview.discounts.amount,
      percentage: preview.discounts.percentage
    };
  }

  getAccesorials(preview: CorrectionsPreviewRatesModel): CorrectionsAccessorialsModel[] {
    if (!preview.accesorials) return [];
    return preview.accesorials.map(accesorial => {
      return {
        ...new CorrectionsAccessorialsModel(),
        sequenceNbr: accesorial.sequenceNbr,
        id: accesorial.id,
        code: accesorial.code,
        description: accesorial.description,
        rate: accesorial.rate,
        amount: accesorial.amount,
        minimumChargeInd: accesorial.minimumChargeInd
      }
    }) as CorrectionsAccessorialsModel[];
  }

  getCommodities(preview: CorrectionsPreviewRatesModel): CorrectionCommoditiesModel[] {
    if (!preview.commodities) return [];
    return preview.commodities.map(commodity => {
      return {
        ...new CorrectionCommoditiesModel(),
        id: commodity.id,
        sequenceNbr: commodity.sequenceNbr,
        pieces: commodity.pieces,
        pkg: commodity.pkg,
        hazmat: commodity.hazmat,
        description: commodity.description,
        nmfc: commodity.nmfc,
        class: commodity.class,
        weight: commodity.weight,
        rate: commodity.rate,
        amount: commodity.amount,
      }
    }) as CorrectionCommoditiesModel[];
  }
}
