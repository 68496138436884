<div xpoDialog class="dialog">
  <h1 mat-dialog-title>
      {{infoDialog?.title}}
      <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>
  <div mat-dialog-content class="dialog__content">
      {{infoDialog?.description}}
  </div>
  <div mat-dialog-actions class="actions">
      <button
        mat-flat-button
        xpoLargeButton
        xpoButton
        (click)="close()"
      >
      {{infoDialog?.textButton}}
      </button>
  </div>
</div>
