<h1 mat-dialog-title>
  {{dialogTexts.title}}
  <xpo-dialog-close-button></xpo-dialog-close-button>
</h1>

<div mat-dialog-content>
  <p>{{dialogTexts.text}}</p>
  <p>{{dialogTexts.textTwo}}</p>
</div>

<div mat-dialog-actions>
  <button mat-flat-button xpoButton (click)="viewReleaseNotes()" [id]="ids.viewNow">{{dialogTexts.viewNow}}</button>
  <button mat-stroked-button xpoButton (click)="close()" [id]="ids.dismiss">{{dialogTexts.dismiss}}</button>
</div>
