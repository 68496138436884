import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserPreferenceHandlerService } from '@app-store/user-preference/handlers/user-preference-handler.service';
import { ReleaseNotesComponent } from '@shared/components/dialogs/release-notes/release-notes.component';
import { UserPreferenceEnum } from '@shared/enums/common-data/user-preference.enum';
import { UserPreferenceConfig } from '@shared/models/shared/user-preference.model';
import { ApplicationUtilsService } from '@shared/services/application-utils/application-utils.service';
import { map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService {

constructor(
  public dialog: MatDialog,
  public applicationUtilsService: ApplicationUtilsService,
  private userPreferenceHandlerService: UserPreferenceHandlerService
) { }

checkForUpdatedReleaseNotes(buildVersion: string): void {
  this.userPreferenceHandlerService.correctionsBuildVersionPreferencesSuccess$
    .pipe(
      map((preferences) => {
        return preferences?.buildVersion !== buildVersion
      }),
      take(1)
    ).subscribe((doNewReleaseNotesExist: boolean) => {
      if (doNewReleaseNotesExist) {
        this.showReleaseNotes(buildVersion);
      }
    });
  this.userPreferenceHandlerService.getBuildVersionPreference(UserPreferenceEnum.buildVersion);

}

showReleaseNotes(buildVersion: string): void {
  const dialogRef = this.dialog.open(ReleaseNotesComponent);
  dialogRef.componentInstance.viewNow.pipe(take(1)).subscribe(() => this.releaseNotesOpen());
  this.dialog
    ._getAfterAllClosed()
    .pipe(take(1))
    .subscribe(() => this.releaseNotesSelected(buildVersion));
}

releaseNotesSelected(buildVersion: string): void {
  this.userPreferenceHandlerService.dismissNewReleaseNotes(UserPreferenceConfig.buildVersion, buildVersion);
}

releaseNotesOpen(): void {
  window.open(this.applicationUtilsService.releaseNotesLink);
}
}
