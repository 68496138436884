export enum CorrectionsSicFormEnum {
  origin = 'origin',
  destination = 'destination'
};

export enum CorrectionsSicFormLabelEnum {
  origin = 'Origin SIC',
  destination = 'Destination SIC',
};

export enum CorrectionsSicFormIdsEnum {
  origin = 'originSic',
  destination = 'destinationSic',
};

export enum CorrectionsSicFormTitlesEnum {
  sic = 'SIC',
  originDestination = 'Origin/ Destination SIC'
};
