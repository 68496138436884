import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProNumberService } from './pro-number.service';
import { CorrectionsHandler } from '@app-store/corrections/handler/corrections.handler';
import { ConditioningService } from '@xpo-ltl/common-services';



@NgModule({
  providers: [
    ProNumberService,
    CorrectionsHandler,
    ConditioningService
  ],
  imports: [
    CommonModule,
  ],
})
export class ProNumberModule { }
