import { Injectable } from '@angular/core';
import { CorrectionsBillQueryParamsUrlEnum } from '@shared/enums/corrections/bill-details/corrections-bill-details-route.enum';
import { CorrectionsBillQueryParamsUrl } from '@shared/models/corrections/bill-search/corrections-bill-search-route.model';
import { UrlsHistoryQueryParams } from '@shared/models/routes/urls.model';
import { CorrectionsRoutes } from '@shared/routes/corrections-routes.constant';

@Injectable({
  providedIn: 'root'
})
export class UrlsService {

  constructor() { }

  getRootUrl(): string {
    return window.location.origin;
  }

  // HISTORY SCREEN *********************************************************************************************************************
  getHistoryUrl(): string {
    return `${CorrectionsRoutes.main}/${CorrectionsRoutes.billDetail.main}/${CorrectionsRoutes.billDetail.history}`;
  }

  getHistoryQueryParams(params: UrlsHistoryQueryParams): string | CorrectionsBillQueryParamsUrl {
    const { isOpenInNewWindow, mainQueryParam } = params;
    return isOpenInNewWindow  ?
      `${CorrectionsBillQueryParamsUrlEnum.main}=${mainQueryParam}`
      : { [CorrectionsBillQueryParamsUrlEnum.main]: mainQueryParam } as CorrectionsBillQueryParamsUrl;
  }

  // PENDING SCREEN *********************************************************************************************************************
  getPendingUrl(): string {
    return `${CorrectionsRoutes.main}/${CorrectionsRoutes.billDetail.main}/${CorrectionsRoutes.billDetail.pending}`;
  }

  getPendingQueryParams(params: UrlsHistoryQueryParams): string | CorrectionsBillQueryParamsUrl {
    const { isOpenInNewWindow, mainQueryParam } = params;
    return isOpenInNewWindow  ?
      `${CorrectionsBillQueryParamsUrlEnum.main}=${mainQueryParam}`
      : { [CorrectionsBillQueryParamsUrlEnum.main]: mainQueryParam } as CorrectionsBillQueryParamsUrl;
  }
}
