import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { XpoMaintAppConfig, XpoMaintenanceModule } from '@xpo-ltl/maint-ui';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';

import { AngularFireModule } from '@angular/fire';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AppStoreModule } from '@app-store/app-store.module';
import { CorrectionsReduxModule } from '@app-store/corrections/corrections-redux.module';
import { UserPreferenceReduxModule } from '@app-store/user-preference/user-preference-redux.module';
import { InputSearchModule } from '@shared/components/form-fields/input-search/input-search.module';
import { CorrectionsShipmentApiService } from '@shared/services/apis/shipment/correctons-shipment-api.service';
import { UserPreferenceDataApiService } from '@shared/services/apis/user-preferences/user-preferences-data-api.service';
import { SharedModule } from '@shared/shared.module';
import { BillCorrectionApiService } from '@xpo-ltl-2.0/sdk-billcorrection';
import { DataApiModule } from '@xpo-ltl/data-api';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoTabsModule } from '@xpo-ltl/ngx-ltl-core/tabs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    BrowserAnimationsModule,
    // XpoPageNotFoundRoutingModule,
    AppStoreModule,
    SharedModule,
    ConfigManagerModule,
    DataApiModule,
    CorrectionsReduxModule,
    UserPreferenceReduxModule,
    XpoShellModule,
    XpoLtlAuthUiModule,
    XpoTabsModule,
    XpoCardModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    XpoMaintenanceModule.initialize(<XpoMaintAppConfig>{ appName: 'corrections' }), //make sure SdkLoggingModule is imported after XpoMaintenanceModule for better performance. (https://confluence.xpo.com/display/LA/LTL+Maintenance+UI+Setup)
    AppRoutingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    InputSearchModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    CorrectionsShipmentApiService,
    BillCorrectionApiService,
    UserPreferenceDataApiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
