import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '@shared/components/dialogs/info-dialog/info-dialog.component';
import { InfoDialogModel } from '@shared/models/shared/components/dialogs/info-dialog.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InfoDialogService {

  closeAction$ = new EventEmitter<void>();

  constructor(private dialog: MatDialog) { }

  open(infoDialog: InfoDialogModel): Observable<void> {
    const dialogRef = this.dialog.open(InfoDialogComponent);
    dialogRef.componentInstance.infoDialog = infoDialog;

    this.closeAction$ = dialogRef.componentInstance.closeAction$;

    return dialogRef.afterClosed();
  
  }
}
