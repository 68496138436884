import { Injectable } from '@angular/core';
import { ApplicationUtilsService } from '@shared/services/application-utils/application-utils.service';
import { XpoAppSwitcherApplication } from '@xpo-ltl/ngx-auth-ui';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AppSwitcherDataService {
  
  constructor(private applicationUtilsService: ApplicationUtilsService) { }

  getAppSwitcherApps(): Observable<XpoAppSwitcherApplication[]> {
    return of(this.applicationUtilsService.appToDisplayAppSwitcher).pipe(
      map((response: string) => {
        let listOfApps: XpoAppSwitcherApplication[] = [];
        const appsRow: string[] = response.split(';');
        for (const app of appsRow) {
          if (!app) {
            continue;
          }
          const [name, url]: string[] = app.split(',');
          listOfApps.push({ name, url });
        }
        return listOfApps;
      })
    );
  }
}
