import { createFeatureSelector, createReducer, on } from "@ngrx/store";
import { AccessorialModel } from "@shared/models/shared/common-data/accessorials.model";
import { AccessorialsActions } from "../actions/accessorials.actions";

export const accessorialsFeatureKey = 'accessorialsKey';
const accessorialsFeatureSelector = createFeatureSelector<AccessorialsStateModel>(accessorialsFeatureKey);

export interface AccessorialsStateModel {
  accessorials: AccessorialModel[];
};

const initialState: AccessorialsStateModel = {
  accessorials: []
};

const reducer = createReducer(
  initialState,
  on(AccessorialsActions.getAccessorials, (state): AccessorialsStateModel => {
    return {
      ...state,
      accessorials: []
    };
  }),
  on(AccessorialsActions.getAccessorialsSuccess, (state, { accessorials }): AccessorialsStateModel => {
    return {
      ...state,
      accessorials
    };
  }),
);

export const AccessorialsReducer = {
  reducer,
  accessorialsFeatureKey,
  accessorialsFeatureSelector,
  initialState
};
