import { Injectable } from '@angular/core';
import { CorrectionsAuditorSummaryTitleEnum } from '@shared/enums/corrections/auditor-function/corrections-auditor-function.enum';
import { CommonDateMapperService } from '@shared/mappers/common/common-date/common-date-mapper.service';
import { CorrectionsAuditorSummaryGridModel } from '@shared/models/corrections/auditor-function/corrections-auditor-summary.model';
import { CorrectionsRequestSummaryModel } from '@shared/models/corrections/corrections-request-queue-summaries.model';
import {
  CorrectionRequestQueueSummary,
  ListCorrectionRequestQueueSummariesResp,
} from '@xpo-ltl-2.0/sdk-billcorrection';

@Injectable()
export class CorrectionsRequestQueueSummariesMapperService {
  serviceName = 'CorrectionsRequestQueueSummariesMapperService';

  constructor(private commonDateMapperService: CommonDateMapperService) {}

  private getDateTime(dateTime: Date): number | undefined {
    if (!dateTime) return;

    try {
      return dateTime ? this.commonDateMapperService.getTimeStampNumberFromApi(dateTime) : undefined;
    } catch (error) {
      console.log(error, this.serviceName);
      return;
    }
  }

  getRequestQueueSummariesFromApi(
    queueSummaries: ListCorrectionRequestQueueSummariesResp
  ): CorrectionsRequestSummaryModel {
    return {
      queueSummaries: this.getRequestQueueSummariesGrid(queueSummaries.correctionRequestQueueSummaries),
      totalsRow: this.getRequestQueueSummariesTotals(queueSummaries.correctionRequestQueueSummaries),
    };
  }

  getRequestQueueSummariesGrid(
    queueSummaries: CorrectionRequestQueueSummary[] = []
  ): CorrectionsAuditorSummaryGridModel[] {
    return queueSummaries.map((queueSummary) => {
      return {
        fortyEightHours: queueSummary.fortyEightHoursNbr,
        oldestCorrection: this.getDateTime(queueSummary.oldestCorrectionDate),
        overFortyEightHours: queueSummary.over48HoursNbr,
        queueName: queueSummary.queueName,
        today: queueSummary.todayNbr,
        twentyFourHours: queueSummary.twentyFourHoursNbr,
      };
    });
  }

  getRequestQueueSummariesTotals(
    queueSummaries: CorrectionRequestQueueSummary[] = []
  ): CorrectionsAuditorSummaryGridModel {
    const queueSummaryTotals: CorrectionsAuditorSummaryGridModel = queueSummaries.reduce((total, queueSummary) => {
      total.fortyEightHours = (total.fortyEightHours || 0) + queueSummary.fortyEightHoursNbr;
      total.overFortyEightHours = (total.overFortyEightHours || 0) + queueSummary.over48HoursNbr;
      total.today = (total.today || 0) + queueSummary.todayNbr;
      total.twentyFourHours = (total.twentyFourHours || 0) + queueSummary.twentyFourHoursNbr;
      return total;
    }, {} as CorrectionsAuditorSummaryGridModel);
    queueSummaryTotals.queueName = CorrectionsAuditorSummaryTitleEnum.totals;

    return queueSummaryTotals;
  }
}
