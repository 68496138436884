import { Injectable } from '@angular/core';
import { CorrectionsBilltoRespModel } from '@shared/models/corrections/corrections-detail.model';
import { GetInvoiceInstructionHistoryPath, GetInvoicingInstructionsResp, InvInstFullData, InvoiceInstruction } from '@xpo-ltl-2.0/sdk-customer';
import { BillTypeCd, CustomerInvoiceCd } from '@xpo-ltl/sdk-common';

@Injectable()
export class CorrectionsAssociatedBillToMapperService {

  constructor() { }

  getMappedData(apiResponse: GetInvoicingInstructionsResp, billCd: BillTypeCd, madCode: string): CorrectionsBilltoRespModel | undefined {
    if (!apiResponse?.invInstFullData || apiResponse.invInstFullData.length === 0) return;
    const associatedBillToFullData: InvInstFullData | undefined  =
      apiResponse.invInstFullData.find(
        data => data.invoiceInstruction?.invoiceCd === CustomerInvoiceCd.CORRECTED
          && data.invoiceInstruction?.billCd === billCd
      );
    if (!associatedBillToFullData) return;
    if (associatedBillToFullData.madCd === madCode) return;

    const associatedBillTo: CorrectionsBilltoRespModel = {
      customerNumber: associatedBillToFullData?.invoiceInstruction?.bil2CustomerLocationFuncId,
      madCode: associatedBillToFullData?.madCd || '',
      name1: associatedBillToFullData?.bill2CustLocation?.party1?.partyName || '',
      name2: associatedBillToFullData?.bill2CustLocation?.party2?.partyName || '',
      address: associatedBillToFullData?.bill2CustLocation?.customerAddress?.address || '',
      city: associatedBillToFullData?.bill2CustLocation?.customerAddress?.cityName || '',
      state: associatedBillToFullData?.bill2CustLocation?.customerAddress?.stateCd || '',
      zip: associatedBillToFullData?.bill2CustLocation?.customerAddress?.zipCd || '',
      zipRest: associatedBillToFullData?.bill2CustLocation?.customerAddress?.zip4Cd || '',
      country: associatedBillToFullData?.bill2CustLocation?.customerAddress?.countryCd || ''
    };
    return associatedBillTo;
  }

  getRequest(customerNumber: number): GetInvoiceInstructionHistoryPath {
    const request = new GetInvoiceInstructionHistoryPath();
    request.custLocFunctionId = customerNumber;
    return request;
  }
}
