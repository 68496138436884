import { Injectable } from '@angular/core';
import { UnlockCorrectionRequestsRqst, UpdateCorrectionRequestStatusPath, UpdateCorrectionRequestStatusRqst } from '@xpo-ltl-2.0/sdk-billcorrection';
import { CorrectionActionTypeCd } from '@xpo-ltl/sdk-common';

@Injectable()
export class CorrectionsRequestUnlockUpdateStatusMapperService {

  constructor() { }

  getRequest(correctionRequestInstIds: number[]): UnlockCorrectionRequestsRqst {
    const request = new UnlockCorrectionRequestsRqst();
    request.correctionRequestInstIds = correctionRequestInstIds;
    return request;
  }
}
