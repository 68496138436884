import { Injectable } from '@angular/core';
import { UserPreferenceComponentNameType, UserPreferenceResultType } from '@shared/types/general/user-preference.types';
import { HandleErrorApiService } from '@shared/utils/apis/error/handle-error-api.service';
import { DataOptions } from '@xpo-ltl/data-api';
import { Envelope } from '@xpo-ltl/sdk-common';
import {
  GetUserPreferenceQuery,
  GetUserPreferenceResp,
  UpsertUserPreferenceQuery,
  UpsertUserPreferenceRqst,
  UserPreferenceApiService,
} from '@xpo-ltl/sdk-userpreference';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class UserPreferenceDataApiService {
  constructor(
    private userPreferenceApiService: UserPreferenceApiService,
    private handleErrorApiService: HandleErrorApiService
  ) {}

  getUserPreference(
    componentName: UserPreferenceComponentNameType,
    dataOptions: DataOptions = { loadingOverlayEnabled: false }
  ): Observable<UserPreferenceResultType | undefined> {
    const getPreferencesQuery = new GetUserPreferenceQuery();
    getPreferencesQuery.uiComponentName = componentName;
    return this.userPreferenceApiService.getUserPreference(getPreferencesQuery, dataOptions).pipe(
      map((response: Envelope<GetUserPreferenceResp>) => {
        try {
          const rawPreferences: string = (response as any as GetUserPreferenceResp)?.preferences;
          return rawPreferences ? (JSON.parse(rawPreferences) as UserPreferenceResultType) : undefined;
        } catch (error) {
          console.error(`Error parsing the release user preferences for ${componentName}`, error);
          return;
        }
      }),
      catchError((response: Envelope<GetUserPreferenceResp>) => {
        console.error(response);
        return throwError(this.handleErrorApiService.handleError<GetUserPreferenceResp>(response));
      })
    );
  }

  updateUserPreference(
    componentName: UserPreferenceComponentNameType,
    preferences: UserPreferenceResultType,
    dataOptions: DataOptions = { loadingOverlayEnabled: false }
  ): Observable<void> {
    const upsertPreferencesRequest = new UpsertUserPreferenceRqst();
    upsertPreferencesRequest.uiComponentName = componentName;
    upsertPreferencesRequest.preferences = JSON.stringify(preferences);
    const upsertPreferencesQuery = new UpsertUserPreferenceQuery();
    return this.userPreferenceApiService
      .upsertUserPreference(upsertPreferencesRequest, upsertPreferencesQuery, dataOptions)
      .pipe(
        catchError((response) => {
          console.error(response);
          return throwError(this.handleErrorApiService.handleError(response));
        })
      );
  }
}
