import { Injectable } from '@angular/core';
import { Commodity } from '@xpo-ltl/sdk-common';

@Injectable({
  providedIn: 'root'
})
export class CommoditiesMapperService {

  constructor() { }

  getId(commodity: Commodity): string {
    return commodity.sequenceNbr;
  }

  getIdForNewItem(id: number | string): string {
    return `NEW-${id}`;
  }
}
