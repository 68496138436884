import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { SideNavActions } from '../actions/side-nav.actions';
import { CorrectionSideNavSectionEnum } from '@shared/enums/corrections/corrections-sidenav.enum';
import { SideNavSelector } from '../selectors/side-nav.selectors';

@Injectable()
export class SideNavHandler {
  // ACTIONS *******************************************************************************************

  // SELECTORS *****************************************************************************************
  getSidenavOpenSection$ = this.store$.pipe(select(SideNavSelector.getSidenavOpenSection));

  constructor(private store$: Store) {}

  clearSidenavOpenSection(): void {
    this.store$.dispatch(SideNavActions.clearSidenavOpenSection());
  }

  setSidenavOpenSection(sidenavOpenSection: CorrectionSideNavSectionEnum | undefined): void {
    this.store$.dispatch(SideNavActions.setSidenavOpenSection({ sidenavOpenSection }));
  }
}
