import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonDateMapperService {

  constructor() { }

  getTimeStampNumberFromApi(timeStamp: Date): number | undefined {
    if (timeStamp == null) return;
    if (typeof timeStamp !== 'number') {
      throw new Error('getTimeStampNumberFromApi was expected a number');
    }

    return timeStamp as number;
  }
}
