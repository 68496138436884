import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { ScreenHandler } from './handler/screen.handler';
import { ScreenReducer } from './reducer/screen.reducer';

@NgModule({
  imports: [StoreModule.forFeature(ScreenReducer.screenFeatureKey, ScreenReducer.reducer)],
  providers: [ScreenHandler],
})
export class ScreenReduxModule {}
