export enum AccessorialErrorMessageEnum {
    duplicated = 'The accessorial code is already selected',
    invalid = 'Select a valid accessorial',
    required = 'Required',
    pickupDate = 'AC not effective for Pickup Date'
}

export enum AccessorialErrorTypeEnum {
    duplicated = 'duplicated',
    invalid = 'invalid',
    required = 'required',
    pickupDate = 'pickupDate'
}

export enum AccessorialsToCheckEnum {
    APT = 'APT',
    CSD = 'CSD',
    DNC = 'DNC',
    EXC = 'EXC',
    PED = 'PED',
    RSD = 'RSD',
    TDC = 'TDC',
    USX = 'USX'
};

