import { AbstractControl } from "@angular/forms";

export class CorrectionsCustomInBondFormModel {
    number: AbstractControl;
    city: AbstractControl;
    state: AbstractControl;
};

export class CorrectionsCustomInBondModel {
    number: string;
    city: string;
    state: string;
};
