import { AbstractControl } from "@angular/forms";
import { CorrectionsSicFormEnum } from "@shared/enums/corrections/corrections-form/sic/corrections-sic-form.enum";

export class CorrectionsSicFormModel {
    origin: AbstractControl;
    destination: AbstractControl;
};

export class CorrectionSicFormGroupModel {
    [CorrectionsSicFormEnum.destination]: string;
    [CorrectionsSicFormEnum.origin]: string;
};
