export enum CorrectionsRequestStatusEnum {
  keyWordToFilter = 'FILTER',
  applied = 'APPLIED',
  approved = 'APPROVED',
  declined = 'DECLINED',
  errors = 'ERRORS',
  locked = 'LOCKED',
  needMoreInfo = 'NEED_MORE_INFO',
  pending = 'PENDING',
  saved = 'SAVED',
};

export enum CorrectionsRequestStatusLabelEnum {
  keyWordToFilter = 'Filter',
  applied = 'Applied',
  approved = 'Approved',
  declined = 'Declined',
  errors = 'Errors',
  locked = 'Locked',
  needMoreInfo = 'Need More Info',
  pending = 'Pending',
  saved = 'Saved',
};

export enum CorrectionsBillRequestSectionTypeEnum {
  pending = 'PENDING',
  history = 'HISTORY'
};
