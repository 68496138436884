import { formatCurrency } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyXpo',
})
export class CurrencyXpoPipe implements PipeTransform {
  readonly localeCode = {
    us: 'en-US',
  };

  readonly currencySymbol = {
    dollar: '$',
  };

  transform(
    value: number | string,
    localeCode = this.localeCode.us,
    currencySymbol = this.currencySymbol.dollar
  ): string {
    const toString = value?.toString();
    const toNumber = +(toString || (undefined as any));
    return !isNaN(toNumber) ? formatCurrency(toNumber, localeCode, currencySymbol) : '';
  }
}
