import { Injectable } from '@angular/core';
import { RatingMinimumChargeValues } from '@xpo-ltl/rating';
@Injectable({
  providedIn: 'root'
})
export class MinimumChargeService {

  constructor() { }

  getMinimumChargeInd(value: number | string | null): boolean {
    return RatingMinimumChargeValues.includes(`${value}`);
  }
}
