import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClientEmailService {

  constructor() { }

  getSimpleEmailLink(to: string, subject: string): string {
    return `mailto:${to}?subject=${subject}`;
  }

  openEmailLink(emailLink: string): void {
    window.open(emailLink);
  }

}
