export enum CorrectionsConsigneeFormEnum {
  customerNumber = 'customerNumber',
  madCode = 'madCode',
  nameOne = 'nameOne',
  nameTwo = 'nameTwo',
  address = 'address',
  city = 'city',
  state = 'state',
  zipCode = 'zipCode',
  zipCodeComplementation = 'zipCodeComplementation',
  country = 'country',
  creditStatus = 'creditStatus',
};

export enum CorrectionsFormConsigneeLabelEnum {
    madCode = 'MAD CODE',
    name1 = 'NAME 1',
    name2 = 'NAME 2',
    address = 'ADDRESS',
    country = 'COUNTRY',
    creditStatus = 'CREDIT STATUS',
    cityStateZip = 'CITY, STATE, ZIP',
    city = 'CITY',
    state = 'STATE',
    zip = 'ZIP',
    applyConsigneeButton = 'APPLY NEW CONSIGNEE BILL-TO INVOICING INSTRUCTIONS',
    originalData = 'Original Data',
    correctedData = 'Corrected Data'
};

export enum CorrectionsConsigneeTitlesEnum {
    title = 'Consignee'
};

export enum CorrectionsConsigneeFormIdsEnum {
  address = 'addressConsigneeId',
  city = 'cityConsigneeId',
  country = 'countryConsigneeId',
  madCode = 'madCodeConsigneeId',
  nameOne = 'nameOneConsigneeId',
  nameTwo = 'nameTwoConsigneeId',
  state = 'stateConsigneeId',
  undoBtn = 'undoBtnConsigneeId',
  zipCode = 'zipCodeConsigneeId',
  zipCodeComplementation = 'zipCodeComplementationConsigneeId',
  associatedBillToBtn = 'consigneeAssociatedBillToBtn',
};
