import { createSelector } from "@ngrx/store";
import { UserReducer, UserStateModel } from "../reducer/user.reducer";

const selector = createSelector(UserReducer.featureSelector, (state) => state);

const getLoggedUser = createSelector(
  selector,
  (state: UserStateModel) => state.user
);

export const UserSelectors = {
  getLoggedUser
};
