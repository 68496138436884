export enum CorrectionsShipperFormEnum {
    customerNumber = 'customerNumber',
    madCode = 'madCode',
    nameOne = 'nameOne',
    nameTwo = 'nameTwo',
    address = 'address',
    city = 'city',
    state = 'state',
    zipCode = 'zipCode',
    zipCodeComplementation = 'zipCodeComplementation',
    country = 'country',
    creditStatus = 'creditStatus',
};

export enum CorrectionsFormShipperLabelEnum {
    madCode = 'MAD CODE',
    name1 = 'NAME 1',
    name2 = 'NAME 2',
    address = 'ADDRESS',
    country = 'COUNTRY',
    creditStatus = 'CREDIT STATUS',
    cityStateZip = 'CITY, STATE, ZIP',
    city = 'CITY',
    state = 'STATE',
    zip = 'ZIP',
    originalData = 'Original Data',
    correctedData = 'Corrected Data',
    applyShipperButton = 'APPLY NEW SHIPPER BILL-TO INVOICING INSTRUCTIONS'
};

export enum CorrectionsShipperTitleEnum {
    title = 'Shipper',
};

export enum CorrectionsShipperFormIdsEnum {
  address = 'addressShipperId',
  city = 'cityShipperId',
  country = 'countryShipperId',
  madCode = 'madCodeShipperId',
  nameOne = 'nameOneShipperId',
  nameTwo = 'nameTwoShipperId',
  state = 'stateShipperId',
  undoBtn = 'undoBtnShipperId',
  zipCode = 'zipCodeShipperId',
  zipCodeComplementation = 'zipCodeComplementationShipperId',
  associatedBillToBtn = 'shipperAssociatedBillToBtn',
}
