import { Injectable } from '@angular/core';
import { CorrectionsMessagesValue } from '@shared/data-values/corrections/messages.data';
import { ErrorModel } from '@shared/models/shared/error.model';
import { ErrorCodesService } from '@shared/services/apis-errors/error-codes/error-codes.service';
import { validateApiTechnicalCodeError } from '@shared/utils/regex/regex.validations';
import { Envelope, MoreInfo } from '@xpo-ltl/sdk-common';

@Injectable({
  providedIn: 'root',
})
export class HandleErrorApiService {
  constructor(private errorCodesService: ErrorCodesService) { }

  handleError<T>(errorEnvelope: Envelope<T>, generalErrorMessage: string = CorrectionsMessagesValue.apis.serviceError): ErrorModel[] {
    if (validateApiTechnicalCodeError(errorEnvelope?.code || '')) {
      if (!this.errorCodesService.isApiErrorCode(errorEnvelope?.error?.errorCode)) {
        const error = new ErrorModel();
        error.message = generalErrorMessage;
        error.code = errorEnvelope?.code;
        return [error];
      }
    }

    if (errorEnvelope?.error?.moreInfo?.length > 0) {
      return errorEnvelope.error.moreInfo.map((infoError: MoreInfo) => {
        const error = new ErrorModel();
        error.message = infoError.message;
        error.location = infoError.location;
        error.code = errorEnvelope?.code
        error.errorCode = errorEnvelope?.error?.errorCode;
        error.generalMessage = errorEnvelope?.error?.message;

        return error;
      });
    }

    const error = new ErrorModel();
    error.message = generalErrorMessage;
    error.code = errorEnvelope?.code
    error.errorCode = errorEnvelope?.error?.errorCode;
    error.generalMessage = errorEnvelope?.error?.message;
    return [error];
  }
}
