import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { InfoDialogModel } from '@shared/models/shared/components/dialogs/info-dialog.model';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoDialogComponent {
  @Input() infoDialog: InfoDialogModel;
  @Output() closeAction$ = new EventEmitter<void>();

  constructor(public dialogRef: MatDialogRef<InfoDialogComponent>) { }

  close(): void {
    this.closeAction$.emit();
    this.dialogRef.close();
  }

}
