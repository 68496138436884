import { Injectable } from "@angular/core";
import { Actions } from '@ngrx/effects';
import { select, Store } from "@ngrx/store";
import { UserActions } from "../actions/user.actions";
import { UserSelectors } from "../selectors/user.selectors";

@Injectable({
    providedIn: 'root'
})
export class UserHandler {
    // SELECTORS *****************************************************************************************
    getLoggedUser$ = this.store$.pipe(
        select(UserSelectors.getLoggedUser)
    );

    constructor(private store$: Store, private actions$: Actions) { }

    getUser(): void {
        this.store$.dispatch(UserActions.getUser());
    }
}
