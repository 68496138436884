import { AbstractControl, FormGroup } from "@angular/forms";
import { CorrectionsAsWeightFormEnum, CorrectionsDeficitWeightFormEnum, CorrectionsWeightFormEnum, CorrectionsWeightFormFieldsEnum, CorrectionsWeightFormTypeEnum } from "@shared/enums/corrections/corrections-form/weight/corrections-weight-form.enum";

class CorrectionsWeightTypeFormModel {
  type: AbstractControl;
  description: AbstractControl;
  quantity: AbstractControl;
  rate: AbstractControl;
  amount: AbstractControl;
  sequenceNbr: AbstractControl;
  minimumChargeInd: AbstractControl;
};

export class CorrectionsAsWeightTypeFormModel extends CorrectionsWeightTypeFormModel { };
export class CorrectionsDeficitWeightTypeFormModel {
  [CorrectionsDeficitWeightFormEnum.type]: AbstractControl;
  [CorrectionsDeficitWeightFormEnum.description]: AbstractControl;
  [CorrectionsDeficitWeightFormEnum.quantity]: AbstractControl;
  [CorrectionsDeficitWeightFormEnum.rate]: AbstractControl;
  [CorrectionsDeficitWeightFormEnum.amount]: AbstractControl;
  [CorrectionsDeficitWeightFormEnum.sequenceNbr]: AbstractControl;
  [CorrectionsDeficitWeightFormEnum.minimumChargeInd]: AbstractControl;
};

export class CorrectionsWeightFormModel {
  optionSelected: AbstractControl;
  deleteWeight: AbstractControl;
  asWeight: FormGroup;
  deficitWeight: FormGroup;
};

export class CorrectionsWeightTypeModel {
  [CorrectionsWeightFormFieldsEnum.type]: CorrectionsWeightFormTypeEnum;
  [CorrectionsWeightFormFieldsEnum.description]: string;
  [CorrectionsWeightFormFieldsEnum.quantity]: number | string;
  [CorrectionsWeightFormFieldsEnum.rate]: number | string;
  [CorrectionsWeightFormFieldsEnum.amount]: number | string;
  [CorrectionsWeightFormFieldsEnum.sequenceNbr]: string;
  [CorrectionsWeightFormFieldsEnum.minimumChargeInd]: boolean;
};

export class CorrectionsWeightModel {
  optionSelected: CorrectionsWeightFormTypeEnum;
  deleteWeight: boolean;
  asWeight: CorrectionsWeightTypeModel;
  deficitWeight: CorrectionsWeightTypeModel;
};

export class CorrectionAsWeightFormGroupModel {
  [CorrectionsAsWeightFormEnum.type]: any;
  [CorrectionsAsWeightFormEnum.description]: any;
  [CorrectionsAsWeightFormEnum.quantity]: any;
  [CorrectionsAsWeightFormEnum.rate]: any;
  [CorrectionsAsWeightFormEnum.amount]: any;
  [CorrectionsAsWeightFormEnum.minimumChargeInd]: any;
};

export class CorrectionDeficitWeightFormGroupModel {
  [CorrectionsDeficitWeightFormEnum.type]: any;
  [CorrectionsDeficitWeightFormEnum.description]: any;
  [CorrectionsDeficitWeightFormEnum.quantity]: any;
  [CorrectionsDeficitWeightFormEnum.rate]: any;
  [CorrectionsDeficitWeightFormEnum.amount]: any;
  [CorrectionsDeficitWeightFormEnum.minimumChargeInd]: any;
};

export class CorrectionWeightFormGroupModel {
  [CorrectionsWeightFormEnum.deleteWeight]: any;
  [CorrectionsWeightFormEnum.asWeight]: CorrectionAsWeightFormGroupModel;
  [CorrectionsWeightFormEnum.deficitWeight]: CorrectionDeficitWeightFormGroupModel;
};
