import { ListArPaymentDetailsByProResp } from "@xpo-ltl-2.0/sdk-accountsreceivable";

export class CorrectionsBillChargesModel {
  adjustments: number;
  balanceDue: number;
  charges: number;
  ctsRev: number;
  payments: number;
  paymentDetails: ListArPaymentDetailsByProResp;
}

export class CorrectionsBillChargesGetModel {
  proNbr: string;
  pickupDate: string;
  ctsRev: number;
  charges: number
}
