import { Injectable } from '@angular/core';
import { CorrectionsDateTimeTypesEnum } from '@shared/enums/corrections/corrections-form/timeDeliveryWindow/corrections-time-delivery-window-form.enum';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TdcCommonMapperService {

  constructor() { }

  isSelectedField(selectedType: string | undefined, expectedType: CorrectionsDateTimeTypesEnum, value: string): string {
    if (selectedType === CorrectionsDateTimeTypesEnum.at && expectedType === CorrectionsDateTimeTypesEnum.range) return value;
    return (selectedType === expectedType) ? value : '';
  }

  getTimeSelectionValue(tdcTimeTypeCd: string): string {
    if (tdcTimeTypeCd === CorrectionsDateTimeTypesEnum.at) return CorrectionsDateTimeTypesEnum.range;
    else if (isEmpty(tdcTimeTypeCd)) return CorrectionsDateTimeTypesEnum.none;
    return tdcTimeTypeCd;
  }
}
