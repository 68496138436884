import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { UserPreferenceActions } from '../actions/user-preference.actions';
import { UserPreferenceEnum } from '@shared/enums/common-data/user-preference.enum';
import { UserPreferenceResultType } from '@shared/types/general/user-preference.types';
import { UserPreferenceConfig } from '@shared/models/shared/user-preference.model';

const userPreferenceFeatureKey = 'userPreference';
const userPreferenceFeatureSelector = createFeatureSelector<UserPreferenceState>(userPreferenceFeatureKey);

export interface UserPreferenceState {
  [UserPreferenceEnum.defaultQueue]: UserPreferenceResultType | undefined;
  [UserPreferenceEnum.buildVersion]: UserPreferenceResultType | undefined;
}

const userPreferenceInitState: UserPreferenceState = {
  [UserPreferenceEnum.defaultQueue]: {defaultQueue: UserPreferenceConfig.userPreferenceInitialState.defaultQueue},
  [UserPreferenceEnum.buildVersion]: {buildVersion: UserPreferenceConfig.userPreferenceInitialState.buildVersion}
};

const reducer = createReducer(
  userPreferenceInitState,
  on(UserPreferenceActions.getDefaultQueuePreference, (state, { componentName }) => {
    return {
      ...state,
      [componentName]: userPreferenceInitState[componentName],
    };
  }),
  on(
    UserPreferenceActions.getDefaultQueuePreferenceSuccess,
    UserPreferenceActions.updateDefaultQueuePreferenceSuccess,
    (state, { componentName, preferences }) => {
      return {
        ...state,
        [componentName]: preferences,
      };
    }
  ),
  on(UserPreferenceActions.getBuildVersionPreference, (state, { componentName }) => {
    return {
      ...state,
      [componentName]: userPreferenceInitState[componentName],
    };
  }),
  on(
    UserPreferenceActions.getBuildVersionPreferenceSuccess,
    UserPreferenceActions.updateBuildVersionPreferenceSuccess,
    (state, { componentName, preferences }) => {
      return {
        ...state,
        [componentName]: preferences,
      };
    }
  ),
);

export const UserPreferenceReducer = {
  reducer,
  userPreferenceFeatureKey,
  userPreferenceFeatureSelector,
  userPreferenceInitState,
};
