import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '@shared/utils/date/date-utils.service';
import { RatingXpoClassPipe } from '@xpo-ltl/rating';
import { CommodityClassCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'commoditiesClassPipe',
})
export class CommoditiesClassPipe implements PipeTransform {
  constructor(private ratingXpoClassPipe: RatingXpoClassPipe) {}

  transform(classType: CommodityClassCd): string {
    return `CLASS ${this.ratingXpoClassPipe.transform(classType)}`;
  }
}
