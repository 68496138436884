import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EmailMessagesValue } from '@shared/data-values/corrections/email.data';
import { EmailEnum } from '@shared/enums/common-data/email.enum';
import { MainFooterIdEnum, MainFooterLabelEnum } from '@shared/enums/common-data/main-footer.enum';
import { ApplicationUtilsService } from '@shared/services/application-utils/application-utils.service';
import { ClientEmailService } from '@shared/services/email/client-email/client-email.service';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainFooterComponent {
  readonly buildVersion: string = this.applicationUtilsService.buildVersion;
  readonly ids = MainFooterIdEnum;
  readonly labels = MainFooterLabelEnum;

  constructor(
    private applicationUtilsService: ApplicationUtilsService,
    private clientEmailService: ClientEmailService
  ) {}

  getMailLink(): string {
    return this.clientEmailService.getSimpleEmailLink(EmailEnum.ltlItRatingTeam, EmailMessagesValue.feedback.subject);
  }

  openHelpLink(): void {
    if (!this.applicationUtilsService.helpLink) return;
    window.open(this.applicationUtilsService.helpLink);
  }

  openReleaseNotesLink(): void {
    if (!this.applicationUtilsService.releaseNotesLink) return;
    window.open(this.applicationUtilsService.releaseNotesLink);
  }
}
