export enum MainFooterIdEnum {
  feedback = 'footer-feedback',
  help = 'footer-help',
  releaseNotes = 'footer-release-notes',
}

export enum MainFooterLabelEnum {
  feedback = 'Feedback',
  help = 'Help',
  releaseNotes = 'Release Notes',
}
