import { Pipe, PipeTransform } from '@angular/core';
import { ProNumberService } from '@shared/services/features/pro-number/pro-number.service';

@Pipe({
  name: 'proNumber',
})
export class ProNumberPipe implements PipeTransform {
  constructor(private proNumberService: ProNumberService) { }

  transform(proNumberInput: string): string {
    if (!Number(proNumberInput)) {
      return proNumberInput;
    }

    return this.proNumberService.getProNbrFormat(proNumberInput, 10);
  }
}
