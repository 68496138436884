import { Pipe, PipeTransform } from '@angular/core';
import { CorrectionsRequestStatusLabelEnum } from '@shared/enums/corrections/corrections-request-status.enum';
import { CorrectionsRequestAndHistoryAuditModel } from '@shared/models/corrections/corrections-request-and-history.model';

@Pipe({
  name: 'pendingStatus'
})
export class PendingStatusPipe implements PipeTransform {

  transform(audit: CorrectionsRequestAndHistoryAuditModel): string {
    if (audit.status === CorrectionsRequestStatusLabelEnum.pending && audit.informationRequestInd) {
      return `${CorrectionsRequestStatusLabelEnum.pending} - ${CorrectionsRequestStatusLabelEnum.needMoreInfo}`;
    }
    return audit.status;
  }
}
