import { Injectable } from '@angular/core';
import { EmailMessagesValue } from '@shared/data-values/corrections/email.data';
import { ApiErrorCodesEnum } from '@shared/enums/common-data/api-error-codes.enum';
import { EmailEnum } from '@shared/enums/common-data/email.enum';
import { UnratedProDialogEnum } from '@shared/enums/common-data/unrated-pro.enum';
import { InfoDialogModel } from '@shared/models/shared/components/dialogs/info-dialog.model';
import { ErrorModel } from '@shared/models/shared/error.model';
import { UnratedProModel } from '@shared/models/shared/unrated-pro.model';
import { InfoDialogService } from '@shared/services/dialogs/info-dialog/info-dialog.service';
import { ClientEmailService } from '@shared/services/email/client-email/client-email.service';
import { RouterNavigationCorrectionsService } from '@shared/services/router-navigation/corrections/router-navigation-corrections.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UnratedProService {

  constructor(
    private infoDialogService: InfoDialogService,
    private clientEmailService: ClientEmailService,
    private routerNavigationCorrectionsService: RouterNavigationCorrectionsService
  ) { }

  handleUnratedPro(unratedPro: UnratedProModel): Function {
    return (errors: ErrorModel[]) => {
      const error = errors?.find(err => err.errorCode === ApiErrorCodesEnum.unratedPro);
      if (!error) return;
      this.openDialog(unratedPro, error);
      this.disableToast(error);
    }
  }

  private disableToast(error: ErrorModel): void {
    error.disableToastErrorMessage = true;
  }

  private openDialog(unratedPro: UnratedProModel, error: ErrorModel): void {
    const { proNbr, redirectToMainScreen } = unratedPro;

    const infoDialog: InfoDialogModel = {
      title: UnratedProDialogEnum.title,
      description: error.generalMessage || '',
      textButton: UnratedProDialogEnum.textButton,
    };

    error.disableToastErrorMessage = true;

    this.infoDialogService.open(infoDialog).pipe(
      take(1)
    ).subscribe(
      () => {
        const emailLink = this.clientEmailService.getSimpleEmailLink(EmailEnum.lTLnightaudit, EmailMessagesValue.unratedPro.subject(proNbr));
        this.clientEmailService.openEmailLink(emailLink);
        this.redirectToMainScreen(redirectToMainScreen);
      }
    );
  }

  private redirectToMainScreen(redirectToMainScreen: boolean): void {
    if (!redirectToMainScreen) return;
    this.routerNavigationCorrectionsService.goToBillDetailsSearch();
  }
}
