import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { storeDevToolsConfig } from './app-store.config';
import { appReducers } from './app-store.reducer';
import { ScreenReduxModule } from './common-data/screen/screen-redux.module';
import { UserEffects } from './user/effects/user.effect';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(appReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: true,
      },
    }),
    EffectsModule.forRoot([
      UserEffects
    ]),
    ...storeDevToolsConfig(environment.enableDevTools),
    ScreenReduxModule,
  ],
})
export class AppStoreModule {}
