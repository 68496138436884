import { AbstractControl } from "@angular/forms";
import { CorrectionsConsigneeFormEnum } from "@shared/enums/corrections/corrections-form/consignee/corrections-consignee-form.enum";
import { ShipmentCreditStatusCd } from "@xpo-ltl/sdk-common";
import { CorrectionsCustomerFormModel, CorrectionsCustomerModel } from "../customer/customer-form.model";

export class CorrectionsConsigneeFormModel implements CorrectionsCustomerFormModel {
  customerNumber: AbstractControl;
  madCode: AbstractControl;
  nameOne: AbstractControl;
  nameTwo: AbstractControl;
  address: AbstractControl;
  city: AbstractControl;
  state: AbstractControl;
  zipCode: AbstractControl;
  zipCodeComplementation: AbstractControl;
  country: AbstractControl;
  creditStatus: AbstractControl;
};

export class CorrectionsConsigneeModel implements CorrectionsCustomerModel {
  [CorrectionsConsigneeFormEnum.customerNumber]: number;
  [CorrectionsConsigneeFormEnum.madCode]: string;
  [CorrectionsConsigneeFormEnum.nameOne]: string;
  [CorrectionsConsigneeFormEnum.nameTwo]: string;
  [CorrectionsConsigneeFormEnum.address]: string;
  [CorrectionsConsigneeFormEnum.city]: string;
  [CorrectionsConsigneeFormEnum.creditStatus]: ShipmentCreditStatusCd;
  [CorrectionsConsigneeFormEnum.state]: string;
  [CorrectionsConsigneeFormEnum.zipCode]: string;
  [CorrectionsConsigneeFormEnum.zipCodeComplementation]: string;
  [CorrectionsConsigneeFormEnum.country]: string;
};
