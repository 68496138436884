export enum CorrectionsAutoRatePreviewTitlesEnum {
  title = 'Auto Rated Preview'
};

export enum CorrectionsAutoRatePreviewErrorsMessageEnum {
  message = 'This Bill Is Not Auto Ratable'
};

export enum CorrectionsAutoRatePreviewAsDeficitWeightEnum {
  asWeight = 'AS WEIGHT',
  deficitWeight = 'DEFICIT WEIGHT'
}
