import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputSearchComponent } from './input-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '@shared/shared.module';



@NgModule({
  declarations: [
    InputSearchComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    SharedModule,
    XpoIconModule
  ],
  exports: [
    InputSearchComponent
  ]
})
export class InputSearchModule { }
