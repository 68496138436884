import { Injectable } from '@angular/core';
import { CorrectionsHandler } from '@app-store/corrections/handler/corrections.handler';
import { CorrectionsBillQueryParamsUrlEnum } from '@shared/enums/corrections/bill-details/corrections-bill-details-route.enum';
import { CorrectionsBillChargesGetModel, CorrectionsBillChargesModel } from '@shared/models/corrections/bill-payment/corrections-bill-charges.model';
import { CorrectionsBillQueryParamsUrl } from '@shared/models/corrections/bill-search/corrections-bill-search-route.model';
import { CorrectionsDetailModel } from '@shared/models/corrections/corrections-detail.model';
import { ErrorModel } from '@shared/models/shared/error.model';
import { snackBarConfig, SnackBarService } from '@shared/services/application-utils/snack-bar/snack-bar.service';
import { RouterNavigationCorrectionsService } from '@shared/services/router-navigation/corrections/router-navigation-corrections.service';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';

@Injectable()
export class CorrectionsDetailDataService {
  constructor(
    private correctionsDetailHandler: CorrectionsHandler,
    private routerNavigationCorrectionsService: RouterNavigationCorrectionsService,
    private snackBarService: SnackBarService
  ) {}

  getProNbr(): string {
    const mainQueryParam: CorrectionsBillQueryParamsUrl = this.getQueryParams(CorrectionsBillQueryParamsUrlEnum.main);
    const proNbr = mainQueryParam[CorrectionsBillQueryParamsUrlEnum.main] || '';

    this.correctionsDetailHandler.setProNbr(proNbr);

    return proNbr;
  }

  getQueryParams(...queryParamKeys: CorrectionsBillQueryParamsUrlEnum[]): CorrectionsBillQueryParamsUrl {
    const mainQueryParam: CorrectionsBillQueryParamsUrl = this.routerNavigationCorrectionsService.getBillQueryParams();

    queryParamKeys.forEach((queryParamKey) => {
      const queryParamFound = mainQueryParam[queryParamKey];
      if (!queryParamFound) {
        this.routerNavigationCorrectionsService.goToBillDetailsSearch();
        return;
      }
    });

    return mainQueryParam;
  }

  getCorrectionsDetailFromStore(
    proNbr: string,
    skipToCallApiAgain?: boolean
  ): Observable<CorrectionsDetailModel | undefined> {
    return this.correctionsDetailHandler.getCorrectionsDetail$.pipe(
      exhaustMap((correctionsDetail) => {
        if (!skipToCallApiAgain || !correctionsDetail) {
          this.correctionsDetailHandler.getDetails(proNbr);
          return this.getDetailFromApi();
        }
        return of(correctionsDetail);
      })
    );
  }

  getDetailFromApi(): Observable<CorrectionsDetailModel | undefined> {
    return merge(
      this.correctionsDetailHandler.getDetailsSuccess$,
      this.correctionsDetailHandler.getDetailsFail$.pipe(
        tap(errors => this.snackBarService.openErrorSnackBar({
            message: errors[0].message,
            avoidToDisplay: errors[0].disableToastErrorMessage,
            duration: snackBarConfig.duration.infinity
          })
        ),
        map(() => undefined)
      ),
    );
  }

  getBillChargesFromStore(): Observable<CorrectionsBillChargesModel | ErrorModel[]> {
    return this.correctionsDetailHandler.getCorrectionsDetail$.pipe(
      exhaustMap((originalDetail) => {
        const billChargesGetVariables: CorrectionsBillChargesGetModel = {
          proNbr: originalDetail?.proNbr as string,
          pickupDate: originalDetail?.pickupDate as string,
          charges: originalDetail?.totalChargeAmount as number,
          ctsRev: originalDetail?.ctsRevenueAmount as number,
        }
        this.correctionsDetailHandler.getBillCharges(billChargesGetVariables);
        return this.getBillChargesFromApi();
      })
    )
  }

  getBillChargesFromApi(): Observable<CorrectionsBillChargesModel | ErrorModel[]> {
    return merge(
      this.correctionsDetailHandler.getBillChargesSuccess$,
      this.correctionsDetailHandler.getBillChargesFail$
    );
  }
}
