import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AccessorialsActions } from '../actions/accessorials.actions';
import { AccessorialsSelector } from '../selectors/accessorials.selectors';

@Injectable()
export class AccessorialsHandler {
  // ACTIONS *****************************************************************************************
  getAccessorialsSuccess$ = this.actions$.pipe(
    ofType(AccessorialsActions.getAccessorialsSuccess),
    map((action) => action.accessorials)
  );

  getAccessorialsFail$ = this.actions$.pipe(
    ofType(AccessorialsActions.getAccessorialsFail),
    map((action) => action.errors)
  );

  // SELECTORS *****************************************************************************************
  getAccessorials$ = this.store$.pipe(select(AccessorialsSelector.getAccessorials));

  constructor(private store$: Store, private actions$: Actions) {}

  getAccessorials(pickupDate: string): void {
    this.store$.dispatch(AccessorialsActions.getAccessorials({ pickupDate }));
  }
}
