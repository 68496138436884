import { Injectable } from '@angular/core';
import { UserModel, UserRolesModel } from '@shared/models/shared/user.model';
import { User } from '@xpo-ltl/sdk-common';

@Injectable({
  providedIn: 'root'
})
export class UserApiMapperService {

  constructor() { }

  getUser(user: User): UserModel {
    return {
      userId: user.userId,
      racfId: user.racfId,
      name: user.givenName,
      lastName: user.lastName,
      email: user.emailAddress,
      roles: this.getRoles(user),
      employeeId: user.employeeId
    };
  }

  private getRoles(user: User): UserRolesModel {
    const userRoles: { [key: string]: string } = {};
    user?.roles?.forEach(userRole => userRoles[userRole] = userRole);

    return userRoles;
  }
}
