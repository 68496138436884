import { Injectable } from '@angular/core';

@Injectable()
export class FormStylesService {

  readonly highlightModifiedSectionClass = 'highlight-modified-section';

  getHighLightModifiedSectionClass(): string {
    return this.highlightModifiedSectionClass;
  }

  getAddedOrUpdateModifiedClass(objModifiedFields: any, deleteFieldName?: string): string | undefined {
    if (!objModifiedFields) return;
    if (deleteFieldName && objModifiedFields[deleteFieldName]) return;

    return this.highlightModifiedSectionClass;
  }

  getSectionModifiedClass(fieldName: string, objModifiedFields: any): string | undefined {
    return objModifiedFields && objModifiedFields[fieldName] !== undefined ? this.highlightModifiedSectionClass : undefined;
  }
}
