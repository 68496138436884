import { AbstractControl } from "@angular/forms";
import { CorrectionsCommoditiesFormEnum } from "@shared/enums/corrections/corrections-form/commodities/corrections-commodities-form.enum";
import { CommodityClassCd, CommodityPackageCd } from "@xpo-ltl/sdk-common";

export class CorrectionCommoditiesFormModel {
    id: AbstractControl;
    sequenceNbr: AbstractControl;
    delete: AbstractControl;
    pieces: AbstractControl;
    pkg: AbstractControl;
    hazmat: AbstractControl;
    description: AbstractControl;
    nmfc: AbstractControl;
    class: AbstractControl;
    weight: AbstractControl;
    rate: AbstractControl;
    minimumChargeInd: AbstractControl;
    amount: AbstractControl;
    markedAction: AbstractControl;
};

export class CorrectionCommoditiesFormGroupModel {
    [CorrectionsCommoditiesFormEnum.id]: any;
    [CorrectionsCommoditiesFormEnum.sequenceNbr]: any;
    [CorrectionsCommoditiesFormEnum.delete]: any;
    [CorrectionsCommoditiesFormEnum.pieces]: any;
    [CorrectionsCommoditiesFormEnum.pkg]: any;
    [CorrectionsCommoditiesFormEnum.hazmat]: any;
    [CorrectionsCommoditiesFormEnum.description]: any;
    [CorrectionsCommoditiesFormEnum.nmfc]: any;
    [CorrectionsCommoditiesFormEnum.class]: any;
    [CorrectionsCommoditiesFormEnum.weight]: any;
    [CorrectionsCommoditiesFormEnum.rate]: any;
    [CorrectionsCommoditiesFormEnum.minimumChargeInd]: any;
    [CorrectionsCommoditiesFormEnum.amount]: any;
    [CorrectionsCommoditiesFormEnum.markedAction]: any;
};

export class CorrectionCommoditiesModel {
    [CorrectionsCommoditiesFormEnum.id]?: string;
    [CorrectionsCommoditiesFormEnum.sequenceNbr]: string | null;
    [CorrectionsCommoditiesFormEnum.delete]: boolean | null;
    [CorrectionsCommoditiesFormEnum.pieces]: number | string | null;
    [CorrectionsCommoditiesFormEnum.pkg]: CommodityPackageCd | null;
    [CorrectionsCommoditiesFormEnum.hazmat]: boolean | null;
    [CorrectionsCommoditiesFormEnum.description]: string | null;
    [CorrectionsCommoditiesFormEnum.nmfc]: string | null;
    [CorrectionsCommoditiesFormEnum.class]: CommodityClassCd | null;
    [CorrectionsCommoditiesFormEnum.weight]: number | string | null;
    [CorrectionsCommoditiesFormEnum.rate]: number | string | null;
    [CorrectionsCommoditiesFormEnum.amount]: number | string | null;
    [CorrectionsCommoditiesFormEnum.minimumChargeInd]?: boolean | null;
};
