import { Injectable } from '@angular/core';
import { ShipmentDetailRespModel } from '@shared/models/shipment/shipment-detail.model';
import { GetRatingInformationResp } from '@xpo-ltl-2.0/sdk-shipment';

@Injectable()
export class ShipmentDetailMapperService {

  constructor(
  ) { }

  getMappedData(response: GetRatingInformationResp): ShipmentDetailRespModel {
    const request = new ShipmentDetailRespModel();
    request.billToAgreementId =  response.ratingInformation.billToAgreementId;
    request.shipperAgreementId =  response.ratingInformation.shipperAgreementId;
    request.consigneeAgreementId =  response.ratingInformation.consigneeAgreementId;
    request.billToRulesetNbr =  response.ratingInformation.billToRulesetNbr;
    request.shipperRulesetNbr =  response.ratingInformation.shipperRulesetNbr;
    request.consigneeRulesetNbr =  response.ratingInformation.consigneeRulesetNbr;
    request.pickupDate =  response.ratingInformation.pickupDate;
    request.rulesetOwner =  response.ratingInformation.rulesetOwnerCd;
    return request;
  }
}
