import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNoIndicator',
})
export class YesNoIndicatorPipe implements PipeTransform {
  transform(indicator: boolean, toUpperCase: boolean = true): string | undefined {
    if (typeof indicator !== 'boolean') return;
    const transformedValue = indicator ? 'Yes' : 'No';
    return toUpperCase ? transformedValue.toUpperCase() : transformedValue;
  }
}
