import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CorrectionsAccessorialsFormEnum } from '@shared/enums/corrections/corrections-form/accessorials/corrections-accessorials-form.enum';
import { CorrectionsAccessorialsFormModel } from '@shared/models/corrections/corrections-form/accessorials/corrections-accessorials.model';
import { DateUtils } from '@shared/utils/date/date-utils.service';
import { AccessorialModel } from '@shared/models/shared/common-data/accessorials.model';
import { DateFormat } from '@shared/enums/common-data/date-format.enum';
import { AccessorialErrorMessageEnum, AccessorialErrorTypeEnum } from '@shared/enums/common-data/accessorials.enum';
import { AccessorialsHandler } from '@app-store/common-data/accessorials/handler/accessorials.handler';
import { map, switchMap, take } from 'rxjs/operators';
import { CorrectionsHandler } from '@app-store/corrections/handler/corrections.handler';
import { Observable, combineLatest, of } from 'rxjs';

@Injectable()
export class AccessorialValidatorsService {

  constructor(
    private accessorialsHandler: AccessorialsHandler,
    private correctionsHandler: CorrectionsHandler
  ) { }
  
  validateDuplicatedAccessorialCode (
    accessorialList: FormGroup[],
    isNew: boolean = false,
    errorMessage: string = `Code is duplicated`
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control) return null;
      let duplicatedTimes = 0;
      accessorialList.forEach((acc: FormGroup) => {
        if (acc.controls[CorrectionsAccessorialsFormEnum.code].value === control.value) {
          duplicatedTimes++;
        }
      });

      const maxDuplicatedTimes = isNew ? 0 : 1;

      return duplicatedTimes > maxDuplicatedTimes ? ({ errorMessage }) : null;
    };
  }

  isPickupDateValid(
    accessorialsForm: CorrectionsAccessorialsFormModel):  AsyncValidatorFn {
      return (control: AbstractControl): Observable<ValidationErrors | null> => {
        if (accessorialsForm.delete.value) {
          return of(null);
        }
        
        return combineLatest([
          this.getSelectedAccessorialFromList(control),
          this.getPickupDate()
        ]).pipe(
          switchMap(([selectedAccessorial, pickupDateString]) => {
            if (!selectedAccessorial || !pickupDateString) {
              return of(null);
            }
    
            const pickupDate = DateUtils.getDateFromString(pickupDateString, DateFormat.yearMonthDayFormat);
            const effectiveDate = DateUtils.getDateFromString(selectedAccessorial.effectiveDate, DateFormat.yearMonthDayFormat);
            const expirationDate = DateUtils.getDateFromString(selectedAccessorial.expirationDate, DateFormat.yearMonthDayFormat);
    
            if (pickupDate < effectiveDate || pickupDate > expirationDate) {
              return of({
                errorMessage: AccessorialErrorMessageEnum.pickupDate,
                typeError: AccessorialErrorTypeEnum.pickupDate
              });
            }
            return of(null);
          })
        );
      };
  }

  getPickupDate(): Observable<string | undefined>{
    return this.correctionsHandler.getPickupDate$.pipe(
      take(1)
    );
  }

  getSelectedAccessorialFromList(accCode: AbstractControl): Observable<AccessorialModel | undefined> {
    return this.accessorialsHandler.getAccessorials$.pipe(
      take(1),
      map(accessorials => accessorials.find(acc => acc.code?.toUpperCase() === accCode.value))
    );
  }
}
