import { createFeatureSelector, createReducer, on } from "@ngrx/store";
import { UserModel } from "@shared/models/shared/user.model";
import { UserActions } from "../actions/user.actions";

export const userFeatureKey = 'userKey';
const featureSelector = createFeatureSelector<UserStateModel>(userFeatureKey);

export interface UserStateModel {
  user: UserModel | undefined;
};

const initialState: UserStateModel = {
  user: undefined,
};

const reducer = createReducer(
  initialState,
  on(UserActions.getUser, (state) => {
    return {
      ...state,
      user: undefined
    };
  }),
  on(UserActions.getUserSuccess, (state, { user }) => {
    return {
      ...state,
      user
    };
  }),
);

export const UserReducer = {
 reducer,
 userFeatureKey,
 featureSelector,
 initialState
};
