import { Injectable } from '@angular/core';
import { MiscLineItem } from '@xpo-ltl/sdk-common';
import { CorrectionsAsWeightRespModel, CorrectionsDeficitWeightRespModel, CorrectionsDetailModel } from '@shared/models/corrections/corrections-detail.model';
import { CorrectionsPreviewRatesContentDetailsModel } from '@shared/models/corrections/preview-rates/corrections-preview-rates.model';

@Injectable()
export class CorrectionsPreviewRatesCommonMetadataMapperService {

  getDeficitWeight(response: MiscLineItem | undefined): CorrectionsDeficitWeightRespModel {
    if (!response) return undefined as any;

    return {
      type: response.lineTypeCd,
      description: response.description,
      quantity: response.quantity,
      rate: response.tariffsRate,
      amount: response.amount,
      sequenceNbr: response.sequenceNbr,
      minimumChargeInd: response.minimumChargeInd
    };
  }

  getAsWeight(response: MiscLineItem | undefined): CorrectionsAsWeightRespModel {
    if (!response) return undefined as any;

    return {
      type: response.lineTypeCd,
      description: response.description,
      quantity: response.quantity,
      rate: response.tariffsRate,
      amount: response.amount,
      sequenceNbr: response.sequenceNbr,
      minimumChargeInd: response.minimumChargeInd
    };
  }

  getMappedDataForBillContentDetails(response: CorrectionsDetailModel | undefined ): CorrectionsPreviewRatesContentDetailsModel {
    const billContentDetails: CorrectionsPreviewRatesContentDetailsModel = new CorrectionsPreviewRatesContentDetailsModel();
    billContentDetails.parentProNbr = response?.parentProNbr as string;
    billContentDetails.supplementalReferences = response?.supplementalReferences;
    billContentDetails.billingRemarks = response?.billingRemarks;
    billContentDetails.authorityRemarks = response?.auditorRemarks?.authorityRemarks;
    billContentDetails.shipmentRemarks = response?.shippingRemarks.remarks;
    billContentDetails.billClass = response?.billStatus.billClass;
    billContentDetails.childProNbrs = response?.childProNbrs;
    billContentDetails.cnwyRevenue = response?.totalChargeAmount;
    return billContentDetails;
  }
}
