import { UserPreferenceEnum } from "@shared/enums/common-data/user-preference.enum";

export class UserPreferenceModel {
  defaultQueue?: string | undefined;
  buildVersion?: string | undefined;
}

export class UserPreferenceConfig {
  static readonly defaultQueue = UserPreferenceEnum.defaultQueue;
  static readonly buildVersion = UserPreferenceEnum.buildVersion;
  static readonly userPreferenceInitialState: UserPreferenceModel = { defaultQueue: undefined, buildVersion: 'initState' };
}
