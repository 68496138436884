import { AbstractControl } from "@angular/forms";

export class CorrectionsCodAmountFormModel {
  amount: AbstractControl;
  delete: AbstractControl;
  description: AbstractControl;
  sequenceNbr: AbstractControl;
};

export class CorrectionsCodAmountModel {
  amount: number | string;
  delete: boolean | undefined;
  description: string;
  sequenceNbr: string;
};
