export class RegexRules {
    static currencyMaxFiveCharacters = /^([0-9]{1,5})(\.[0-9]{1,2})?$/;
    static findBlankSpaces = /\s{1,}/g;
    static upperCaseInString = /(?=[A-Z])/;
    static onlyNumbersOneHyphen = /^[0-9]+(-[0-9]+)?$/;
    static getTextBeforeSlash = /.*\//gm;
    static fileNameWithExtension = /\w+\.[a-z]{3}$/;
    static agreementWithHypen = /^[0-9]+-$/;
    static accInstIdFromAutoComplete = /-\s?(?=.*)\d+\s?-/;
    static getHypens = /\-/g;
    static splitWordsByBlankSpace = /(?:\s+)/;
    static mainApplicationRoute = (route: string) => new RegExp(`^\/${route}(\/.+)*$`);
    static dateYearMontDayFormat = /^\d{4}\/\d{1,2}\/\d{1,2}$/;
    static excessiveValueChange = /^\d{1,9}(\.\d{1,2})?$/;
    static proNumberLength = /^[0-9]{9,11}$/;
    static onlyNumbers = /^\d+?/;
    static integer = /^\d+$/;
    static numbersWithFourDecimals = /^(\d+(\.\d{1,4})?)$/;
    static numbersWithTwoDecimals = /^(\d+(\.\d{1,2})?)$/;
    static numbersWithOneDecimals = /^\d+(\.\d)?$/;
    static positiveNegativeNumbersWithTwoDecimals = /^-?\d+(.\d{1,2})?$/;
    static positiveNumberGreaterThanZeroUpToTwoDecimals = /^(?=.*[1-9])\d*(?:\.\d{1,2})?$/;
    static numbersWithDecimals = /^\d+(\.\d+)?$/;
    static spotQuoteNumber = /^([Ss][Qq])?\d{1,18}$/;
    static rateField =  /^(\d+(\.\d{1,4})?|-0\.0001)$/;
    static tariffCode = /^(?=.{1,6}$)(?=(?:[^A-Z]*[A-Z]){0,3}[^A-Z]*$)(?=(?:[^0-9]*[0-9]){3,}[^0-9]*$)[A-Z]*\s?[0-9]*$/;
    static apiTechnicalCodeError = /^5\d{2}$/;
    static onlyNumbersAndMoneySign = /(\d+|\.(\d+)|,(\d+)|-?\d+-?|\$+\d+)/g;
    static pstValidationDate = /\d{1,2}\/\d{1,2}\/\d{4} \d{2}:\d{2}:\d{2} (?:AM|PM) (PDT|PST)/;
}
