import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/shared/error.model';
import { UserPreferenceComponentNameType, UserPreferenceResultType } from '@shared/types/general/user-preference.types';

const getDefaultQueuePreference = createAction(
  '[UserPreferenceActions] Get default queue preference data',
  props<{ componentName: UserPreferenceComponentNameType }>()
);
const getDefaultQueuePreferenceSuccess = createAction(
  '[UserPreferenceActions] Get default queue preference data Success',
  props<{ componentName: UserPreferenceComponentNameType; preferences: UserPreferenceResultType | undefined }>()
);
const getDefaultQueuePreferenceFail = createAction(
  '[UserPreferenceActions] Get default queue preference data Fail',
  props<{ componentName: UserPreferenceComponentNameType; errors: ErrorModel[] }>()
);

const getBuildVersionPreference = createAction(
  '[UserPreferenceActions] Get build version preference data',
  props<{ componentName: UserPreferenceComponentNameType }>()
);
const getBuildVersionPreferenceSuccess = createAction(
  '[UserPreferenceActions] Get build version preference data Success',
  props<{ componentName: UserPreferenceComponentNameType; preferences: UserPreferenceResultType | undefined }>()
);
const getBuildVersionPreferenceFail = createAction(
  '[UserPreferenceActions] Get build version preference data Fail',
  props<{ componentName: UserPreferenceComponentNameType; errors: ErrorModel[] }>()
);

const updateDefaultQueuePreference = createAction(
  '[UserPreferenceActions] Update default queue preference data',
  props<{ componentName: UserPreferenceComponentNameType; preferences: UserPreferenceResultType }>()
);
const updateDefaultQueuePreferenceSuccess = createAction(
  '[UserPreferenceActions] Update default queue preference data Success',
  props<{ componentName: UserPreferenceComponentNameType; preferences: UserPreferenceResultType }>()
);
const updateDefaultQueuePreferenceFail = createAction(
  '[UserPreferenceActions] Update default queue preference data Fail',
  props<{ componentName: UserPreferenceComponentNameType; errors: ErrorModel[] }>()
);

const updateBuildVersionPreference = createAction(
  '[UserPreferenceActions] Update build version preference data',
  props<{ componentName: UserPreferenceComponentNameType; preferences: UserPreferenceResultType }>()
);
const updateBuildVersionPreferenceSuccess = createAction(
  '[UserPreferenceActions] Update build version preference data Success',
  props<{ componentName: UserPreferenceComponentNameType; preferences: UserPreferenceResultType }>()
);
const updateBuildVersionPreferenceFail = createAction(
  '[UserPreferenceActions] Update build version preference data Fail',
  props<{ componentName: UserPreferenceComponentNameType; errors: ErrorModel[] }>()
);

export const UserPreferenceActions = {
  getDefaultQueuePreference,
  getDefaultQueuePreferenceSuccess,
  getDefaultQueuePreferenceFail,
  getBuildVersionPreference,
  getBuildVersionPreferenceSuccess,
  getBuildVersionPreferenceFail,
  updateDefaultQueuePreference,
  updateDefaultQueuePreferenceSuccess,
  updateDefaultQueuePreferenceFail,
  updateBuildVersionPreference,
  updateBuildVersionPreferenceSuccess,
  updateBuildVersionPreferenceFail,
};
