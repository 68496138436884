import { Injectable } from '@angular/core';
import { ScreenHandler } from '@app-store/common-data/screen/handler/screen.handler';
import { ScreenEnum } from '@shared/enums/common-data/screen.enum';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterNavigationCommonService {

  constructor(
    private screenHandler: ScreenHandler
  ) { }

  getCurrentScreen$(): Observable<ScreenEnum | undefined> {
    return this.screenHandler.getCurrentScreen$;
  }

  getPreviousScreen(): Observable<ScreenEnum | undefined> {
    return this.screenHandler.getPreviousScreen$;
  }
}
