export enum DateFormat {
  dateA11y = 'LL',
  dateNumeral = 'l',
  monthYearLabel = 'MMM YYYY',
  monthYearA11yLabel = 'MMMM YYYY',
  dateFormatForDisplay = 'DD/MM/YY',
  yearMonthDayFormat = 'YYYY-MM-DD',
  defaultApiDateFormat = 'MM-DD-YYYY',
  timestamp = 'MM/DD/YYYY hh:mmA',
  defaultScreenDate = 'MM/DD/YYYY',
  formRawValueDates = 'YYYY-MM-DD',
  monthDayYearHourMinuteSecondTimezone = 'MM/DD/YYYY HH:mm:ss A z',
};

export enum DateTimezone {
  local = '',
  pst = 'America/Los_Angeles'
};
