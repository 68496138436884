import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { ScreenEnum } from '@shared/enums/common-data/screen.enum';
import { ScreenActions } from '../actions/screen.actions';

export const screenFeatureKey = 'screenFeatureKey';
const screenFeatureSelector = createFeatureSelector<ScreenStateModel>(screenFeatureKey);

export interface ScreenStateModel {
  currentScreen: ScreenEnum | undefined;
  previousScreen: ScreenEnum | undefined;
}

const initialState: ScreenStateModel = {
  currentScreen: undefined,
  previousScreen: undefined,
};

const reducer = createReducer(
  initialState,
  on(ScreenActions.setCurrentScreen, (state, { currentScreen }): ScreenStateModel => {
    const previousScreen = state.currentScreen;
    return {
      ...state,
      currentScreen,
      previousScreen
    };
  })
);

export const ScreenReducer = {
  reducer,
  screenFeatureKey,
  screenFeatureSelector,
  initialState,
};
