import { Injectable } from '@angular/core';
import { ApiErrorCodesEnum } from '@shared/enums/common-data/api-error-codes.enum';

@Injectable()
export class ErrorCodesService {
  constructor() {}

  isApiErrorCode(errorCode: string): boolean {
    return Object.values(ApiErrorCodesEnum).includes(errorCode as ApiErrorCodesEnum);
  }
}
