import { Injectable } from '@angular/core';
import { BillClassEnum } from '@shared/enums/common-data/bill-class.enum';
import { MetadataUiViewEnum } from '@shared/enums/common-data/metadata.enum';
import {
  CorrectionsListMetadataModel,
  CorrectionsListMetadataQueueModel,
  CorrectionsListMetadataReasonCategoriesModel,
  CorrectionsListMetadataReasonDescriptionModel,
} from '@shared/models/corrections/corrections-list-metadata.model';
import { ListCorrectionMetadataQuery, ListCorrectionMetadataResp } from '@xpo-ltl-2.0/sdk-billcorrection';

@Injectable()
export class CorrectionsListMetadataMapperService {
  constructor() {}

  getRequestForListCorrectionsMetaDataForEdit(uiView: MetadataUiViewEnum, tdcInd: boolean, contentGroupInd: boolean, billClass?: BillClassEnum): ListCorrectionMetadataQuery {
    const queryParams = new ListCorrectionMetadataQuery();
    queryParams.uiView = uiView;
    queryParams.billClassCd = billClass as any;
    queryParams.tdcInd = tdcInd;
    queryParams.contentGroupInd = contentGroupInd;

    return queryParams;
  }

  getRequestForListCorrectionsMetaData(uiView: MetadataUiViewEnum): ListCorrectionMetadataQuery {
    const queryParams = new ListCorrectionMetadataQuery();
    queryParams.uiView = uiView;
    return queryParams;
  }

  getMappedData(metadataApiResponse: ListCorrectionMetadataResp): CorrectionsListMetadataModel {
    return {
      queues: this.getQueueData(metadataApiResponse),
      reasonCategories: this.getReasonCategories(metadataApiResponse),
      reasonDescriptions: this.getReasonDescriptions(metadataApiResponse),
    }
  }

  private getReasonCategories(
    metadataApiResponse: ListCorrectionMetadataResp
  ): CorrectionsListMetadataReasonCategoriesModel[] {
    if (!metadataApiResponse?.reasonCategories) return [];
    return metadataApiResponse.reasonCategories.map((reasonCategory) => ({
      code: reasonCategory.categoryCd,
      description: reasonCategory.categoryDescription,
    }));
  }

  private getReasonDescriptions(
    metadataApiResponse: ListCorrectionMetadataResp
  ): CorrectionsListMetadataReasonDescriptionModel[] {
    if (!metadataApiResponse?.reasons) return [];
    return metadataApiResponse.reasons.map((reasonDescription) => ({
      code: reasonDescription.reasonCd,
      description: reasonDescription.reasonDescription,
      reasonCategoryCode: reasonDescription.categoryCd,
    }));
  }

  private getQueueData(metadataApiResponse: ListCorrectionMetadataResp): CorrectionsListMetadataQueueModel[] {
    if (!metadataApiResponse?.correctionQueues) return [];
    return metadataApiResponse.correctionQueues.map((queue) => ({
      queueName: queue.queueName,
      queueCorpCd: queue.queueCorpCd,
      queueDescription: queue.queueDescription,
    }));
  }
}
