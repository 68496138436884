import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { SideNavReducer } from './reducer/side-nav.reducer';
import { SideNavHandler } from './handler/side-nav.handler';

@NgModule({
  imports: [StoreModule.forFeature(SideNavReducer.sideNavFeatureKey, SideNavReducer.reducer)],
  providers: [SideNavHandler],
})
export class SideNavReduxModule {}
