import { createSelector } from "@ngrx/store";
import { AccessorialsReducer, AccessorialsStateModel } from "../reducer/accessorials.reducer";

const selector = createSelector(AccessorialsReducer.accessorialsFeatureSelector, (state) => state);

const getAccessorials = createSelector(
  selector,
  (state: AccessorialsStateModel) => state.accessorials
);

export const AccessorialsSelector = {
    getAccessorials
};
