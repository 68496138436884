import { Injectable } from '@angular/core';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

export const snackBarConfig = {
  duration: {
    short: 2000,
    mid: 8000,
    long: 15000,
    infinity: 0,
  },
};

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(public snackBar: XpoSnackBar) { }

  openSuccessSnackBar(message: string, duration: number = snackBarConfig.duration.mid): void {
    this.snackBar.open({
      message,
      status: 'success',
      matConfig: {
        duration: duration,
      },
    });
  }

  openErrorSnackBar(errorSnackBar: { message: string, duration?: number, avoidToDisplay?: boolean}): void {
    const { avoidToDisplay, message, duration } = errorSnackBar;
    if (avoidToDisplay) return;

    this.snackBar.open({
      message,
      status: 'error',
      matConfig: {
        duration: duration || snackBarConfig.duration.mid,
      },
    });
  }
}
