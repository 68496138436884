export enum CorrectionsTimeDeliveryWindowFormEnum {
  dateSelection = 'dateSelection',
  endRangeDate = 'endRangeDate',
  endRangeTime = 'endRangeTime',
  initialOnDate = 'initialOnDate',
  initialAfterTime = 'initialAfterTime',
  initialBeforeTime = 'initialBeforeTime',
  initialByDate = 'initialByDate',
  initialRangeDate = 'initialRangeDate',
  initialRangeTime = 'initialRangeTime',
  timeSelection = 'timeSelection',
};

export enum CorrectionsTimeDeliveryWindowFormLabelEnum {
  on = 'ON',
  by = 'BY',
  range = 'RANGE',
  before = 'BEFORE',
  after = 'AFTER',
  none = 'NONE',
  to = 'TO'
};

export enum CorrectionsTimeDeliveryWindowFormTitlesEnum {
  title = 'Time Date Critical Delivery Window',
  originalDate = 'Original Date',
  correctedDate = 'Corrected Date',
  originalTime = 'Original Time',
  correctedTime = 'Corrected Time',
};

export enum CorrectionsDateTimeTypesEnum {
  on = 'ON',
  by = 'BY',
  at = 'AT',
  range = 'RNG',
  before = 'BEF',
  after = 'AFT',
  none = 'NONE'
}
