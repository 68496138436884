export enum RamRolesKeyEnum {
  auditorRamRole = 'auditorRamRole',
  inspectorRamRole = 'inspectorRamRole',
  managerRamRole = 'managerRamRole',
  requester = 'requester',
  supervisorRamRole = 'supervisorRamRole',
  swoAuditorRamRole = 'swoAuditorRamRole',
  vicePresidentRamRole = 'vicePresidentRamRole',
};

export enum RamRolesEnum {
  auditorRamRole = 'Conway_Corr_Auditor',
  inspectorRamRole = 'Conway_Tab_Corr_Inspectors',
  managerRamRole = 'Conway_Corr_Manager',
  requester = 'CONWAY_CORR_RQSTR',
  supervisorRamRole = 'Conway_Corr_Supervisor',
  swoAuditorRamRole = 'LTL_CORR_SWO_Auditor',
  vicePresidentRamRole = 'Conway_Corr_VicePresident',
};

export enum RamRolesTestEnum {
  auditorRamRole = 'TST_Edge_Corr_Auditor',
  inspectorRamRole = 'TST_Edge_Corr_Inspector',
  managerRamRole = 'TST_Edge_Corr_Manager',
  requester = 'TST_Edge_Corr_Requestor',
  supervisorRamRole = 'TST_Edge_Corr_Supervisor',
  swoAuditorRamRole = 'TST_Edge_Corr_SWO_Auditor',
  vicePresidentRamRole = 'TST_Edge_Corr_VP',
};
