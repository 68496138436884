import { Injectable } from '@angular/core';
import { CorrectionsHandler } from '@app-store/corrections/handler/corrections.handler';
import { ConditioningService } from '@xpo-ltl/common-services';
import { take } from 'rxjs/operators';

@Injectable()
export class ProNumberService {

  constructor(
    private correctionsHandler: CorrectionsHandler,
    private conditioningService: ConditioningService
  ) {}

  doesProNumberExist(): boolean {
    let proNbr = '';
    this.correctionsHandler.getProNbr$.pipe(take(1)).subscribe((proNbrFromStore) => (proNbr = proNbrFromStore));
    return !!proNbr;
  }

  getProNbrFormat(proNbr: string, numberOfDigits = 11): string {
    // NOTE: For 10 digits the format is 649-675714, with eleven digits the format is 06490675714
    return this.conditioningService.conditionProNumber(proNbr, numberOfDigits);
  }
}
