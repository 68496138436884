import { Injectable } from '@angular/core';
import { HandleErrorApiService } from '@shared/utils/apis/error/handle-error-api.service';
import { Envelope } from '@xpo-ltl/sdk-common';
import {
  DataServicesApiService,
  GetCustomerSicOverridePath,
  GetCustomerSicOverrideResp,
} from '@xpo-ltl/sdk-dataservices';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class DataServicesDataApiService {
  constructor(
    private dataServicesApiService: DataServicesApiService,
    private handleErrorApiService: HandleErrorApiService
  ) {}

  getCustomerSicOverride(
    pathParams: GetCustomerSicOverridePath,
    dataOptions = { loadingOverlayEnabled: true, toastOnError: false }
  ): Observable<GetCustomerSicOverrideResp> {
    return this.dataServicesApiService.getCustomerSicOverride(pathParams, dataOptions).pipe(
      map((response: Envelope<GetCustomerSicOverrideResp>) => response as any as GetCustomerSicOverrideResp),
      catchError((response) => {
        console.error(response);
        return throwError(this.handleErrorApiService.handleError<Envelope<GetCustomerSicOverrideResp>>(response));
      })
    );
  }
}
