import { AbstractControl } from "@angular/forms";
import { CorrectionsShipperFormEnum } from "@shared/enums/corrections/corrections-form/shipper/corrections-shipper-form.enum";
import { ShipmentCreditStatusCd } from "@xpo-ltl/sdk-common/public_api";
import { CorrectionsCustomerFormModel, CorrectionsCustomerModel } from "../customer/customer-form.model";

export class CorrectionsShipperFormModel implements CorrectionsCustomerFormModel {
    customerNumber: AbstractControl;
    madCode: AbstractControl;
    nameOne: AbstractControl;
    nameTwo: AbstractControl;
    address: AbstractControl;
    city: AbstractControl;
    state: AbstractControl;
    zipCode: AbstractControl;
    zipCodeComplementation: AbstractControl;
    country: AbstractControl;
    creditStatus: AbstractControl;
};

export class CorrectionsShipperModel implements CorrectionsCustomerModel {
  [CorrectionsShipperFormEnum.customerNumber]: number;
  [CorrectionsShipperFormEnum.madCode]: string;
  [CorrectionsShipperFormEnum.nameOne]: string;
  [CorrectionsShipperFormEnum.nameTwo]: string;
  [CorrectionsShipperFormEnum.address]: string;
  [CorrectionsShipperFormEnum.city]: string;
  [CorrectionsShipperFormEnum.creditStatus]: ShipmentCreditStatusCd;
  [CorrectionsShipperFormEnum.state]: string;
  [CorrectionsShipperFormEnum.zipCode]: string;
  [CorrectionsShipperFormEnum.zipCodeComplementation]: string;
  [CorrectionsShipperFormEnum.country]: string;
};
