import { createAction, props } from "@ngrx/store";
import { ErrorModel } from "@shared/models/shared/error.model";
import { UserModel } from "@shared/models/shared/user.model";

export abstract class UserActions {
    static getUser = createAction(
        '[UserActions] Get User data'
    );
    static getUserSuccess = createAction(
        '[UserActions] Get User data Success',
        props<{ user: UserModel }>()
    );
    static getUserFail = createAction(
        '[UserActions] Get User data Fail',
        props<{ errors: ErrorModel[] }>()
    );
}
