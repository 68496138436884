import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { UserPreferenceActions } from '../actions/user-preference.actions';
import { UserPreferenceDataApiService } from '@shared/services/apis/user-preferences/user-preferences-data-api.service';
import { UserPreferenceResultType } from '@shared/types/general/user-preference.types';
import { ErrorModel } from '@shared/models/shared/error.model';

@Injectable()
export class UserPreferenceEffects {
  getDefaultQueuePreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.getDefaultQueuePreference),
      exhaustMap(({ componentName }) => {
        return this.userPreferenceDataApiService.getUserPreference(componentName).pipe(
          map((preferences: UserPreferenceResultType | undefined) =>
            UserPreferenceActions.getDefaultQueuePreferenceSuccess({ componentName, preferences })
          ),
          catchError((errors: ErrorModel[]) =>
            of(UserPreferenceActions.getDefaultQueuePreferenceFail({ componentName, errors }))
          )
        );
      })
    )
  );

  updateDefaultQueuePreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.updateDefaultQueuePreference),
      exhaustMap(({ componentName, preferences }) => {
        return this.userPreferenceDataApiService.updateUserPreference(componentName, preferences).pipe(
          map(() => UserPreferenceActions.updateDefaultQueuePreferenceSuccess({ componentName, preferences })),
          catchError((errors: ErrorModel[]) =>
            of(UserPreferenceActions.updateDefaultQueuePreferenceFail({ componentName, errors }))
          )
        );
      })
    )
  );

  getBuildVersionPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.getBuildVersionPreference),
      exhaustMap(({ componentName }) => {
        return this.userPreferenceDataApiService.getUserPreference(componentName).pipe(
          map((preferences: UserPreferenceResultType | undefined) =>
            UserPreferenceActions.getBuildVersionPreferenceSuccess({ componentName, preferences })
          ),
          catchError((errors: ErrorModel[]) =>
            of(UserPreferenceActions.getBuildVersionPreferenceFail({ componentName, errors }))
          )
        );
      })
    )
  );

  updateBuildVersionPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.updateBuildVersionPreference),
      exhaustMap(({ componentName, preferences }) => {
        return this.userPreferenceDataApiService.updateUserPreference(componentName, preferences).pipe(
          map(() => UserPreferenceActions.updateBuildVersionPreferenceSuccess({ componentName, preferences })),
          catchError((errors: ErrorModel[]) =>
            of(UserPreferenceActions.updateBuildVersionPreferenceFail({ componentName, errors }))
          )
        );
      })
    )
  );

  constructor(private actions$: Actions, private userPreferenceDataApiService: UserPreferenceDataApiService) {}
}
