import { Injectable } from '@angular/core';
import { ChargeToCd, MatchedPartyTypeCd, MiscLineItem, MiscLineItemCd } from '@xpo-ltl/sdk-common';
import { CorrectionsPreviewRatesModel, CorrectionsPreviewRevenuesModel, CorrectionsPreviewTotalsModel } from '@shared/models/corrections/preview-rates/corrections-preview-rates.model';
import { CorrectionsAccessorialRespModel, CorrectionsChargeCodeRespModel, CorrectionsCodAmountRespModel, CorrectionsCommoditiesRespModel, CorrectionsCustomerRespModel, CorrectionsDiscountAmountRespModel, CorrectionsSicRespModel, CorrectionsWeightLineRespModel } from '@shared/models/corrections/corrections-detail.model';
import { GetManualRatesPreviewResp } from '@xpo-ltl-2.0/sdk-billcorrection';
import { CorrectionsWeightFormTypeEnum } from '@shared/enums/corrections/corrections-form/weight/corrections-weight-form.enum';
import { CorrectionsPreviewRatesCommonMetadataMapperService } from '../common/corrections-preview-rates-common-metadata-mapper.service';
import { AccessorialsMapperService } from '@shared/mappers/common/accessorials/utls/accessorials-mapper.service';
import { CommoditiesMapperService } from '@shared/mappers/common/commodities/utils/commodities-mapper.service';

@Injectable()
export class CorrectionsManualRatesMetadataMapperService {
  constructor(
    private correctionsPreviewRatesCommonMetadataMapperService: CorrectionsPreviewRatesCommonMetadataMapperService,
    private accessorialsMapperService: AccessorialsMapperService,
    private commoditiesMapperService: CommoditiesMapperService
  ) {}

  getMappedDataForManual(response: GetManualRatesPreviewResp): CorrectionsPreviewRatesModel {
    const data = new CorrectionsPreviewRatesModel();
    data.pickupDate = response.manualRatingPro.ratingInformation.pickupDate;
    data.chargeCode = this.getChargeCode(response);
    data.codAmount = this.getCodAmount(response);
    data.weightLine = this.getWeightLine(response);
    data.sic = this.getSic(response);
    data.shipper = this.getShipper(response);
    data.consignee = this.getConsignee(response);
    data.billTo = this.getBillTo(response);
    data.discounts = this.getDiscounts(response);
    data.commodities = this.getCommodities(response);
    data.accesorials = this.getAccesorials(response);
    data.revenues = this.getRevenues(response);
    data.totals = this.getTotals(response);
    return data;
  }

  private getWeightLine(response: GetManualRatesPreviewResp): CorrectionsWeightLineRespModel {
    if (!response.manualRatingPro.miscellaneousLineItems) return new CorrectionsWeightLineRespModel();
    const asWeight = response.manualRatingPro.miscellaneousLineItems.find(misc => misc.lineTypeCd === MiscLineItemCd.AS_WGT);
    const deficitWeight = response.manualRatingPro.miscellaneousLineItems.find(misc => misc.lineTypeCd === MiscLineItemCd.DEFICIT_WGT);

    return {
      deleteInd: false,
      typeWeightLine: asWeight ? CorrectionsWeightFormTypeEnum.asWeight : CorrectionsWeightFormTypeEnum.deficitWeight,
      asWeight: this.correctionsPreviewRatesCommonMetadataMapperService.getAsWeight(asWeight),
      deficitWeight: this.correctionsPreviewRatesCommonMetadataMapperService.getDeficitWeight(deficitWeight)
    };
  }

  private getCodAmount(response: GetManualRatesPreviewResp): CorrectionsCodAmountRespModel {
    if (!response.manualRatingPro.miscellaneousLineItems) return new CorrectionsCodAmountRespModel();
    const codAmount = response.manualRatingPro.miscellaneousLineItems.find(misc => misc.lineTypeCd === MiscLineItemCd.COD_AMT);
    return {
      ... new CorrectionsCodAmountRespModel(),
      ...codAmount
    };
  }

  private getChargeCode(response: GetManualRatesPreviewResp): CorrectionsChargeCodeRespModel {
    const chargeCode = new CorrectionsChargeCodeRespModel();
    if (response.manualRatingPro.ratingInformation.chargeToCd !== ChargeToCd.BOTH) {
      chargeCode.chargeCode = response.manualRatingPro.ratingInformation.chargeToCd === ChargeToCd.PPD ? ChargeToCd.PPD : ChargeToCd.COLL;
      return chargeCode;
    }
    const ppd = response.manualRatingPro.miscellaneousLineItems.find(misc => misc.lineTypeCd === MiscLineItemCd.PART_PPD_LN) ?? new MiscLineItem();
    const coll = response.manualRatingPro.miscellaneousLineItems.find(misc => misc.lineTypeCd === MiscLineItemCd.PART_COLL_LN) ?? new MiscLineItem();
    return {
      ...chargeCode,
      chargeCode: ChargeToCd.BOTH,
      ppdText: ppd?.description,
      ppdAmount: ppd?.amount,
      ppdSequenceNbr: ppd?.sequenceNbr,
      colText: coll?.description,
      colAmount: coll?.amount,
      colSequenceNbr: coll?.sequenceNbr
    }
  }

  private getSic(response: GetManualRatesPreviewResp): CorrectionsSicRespModel {
    return {
      origin: response.manualRatingPro.ratingInformation.originTerminalSicCd,
      destination: response.manualRatingPro.ratingInformation.destinationTerminalSicCd
    };
  }
  
  private getShipper(response: GetManualRatesPreviewResp): CorrectionsCustomerRespModel {
    return {
      address: response.shipper?.address,
      city: response.shipper?.city,
      chrgsResp: MatchedPartyTypeCd.BILL_TO_INB,
      customerNumber: response.shipper?.cisCustNbr,
      madCode: response.shipper?.asMatchedMadCd,
      country: response.shipper?.countryCd,
      name1: response.shipper?.name1,
      name2: response.shipper?.name2,
      state: response.shipper?.stateCd,
      zip: response.shipper?.zip6,
      zipRest: response.shipper?.zip4RestUs,
    };
  }

  private getConsignee(response: GetManualRatesPreviewResp): CorrectionsCustomerRespModel {
    return {
      address: response.consignee?.address,
      city: response.consignee?.city,
      chrgsResp: MatchedPartyTypeCd.BILL_TO_INB,
      customerNumber: response.consignee?.cisCustNbr,
      madCode: response.consignee?.asMatchedMadCd,
      country: response.consignee?.countryCd,
      name1: response.consignee?.name1,
      name2: response.consignee?.name2,
      state: response.consignee?.stateCd,
      zip: response.consignee?.zip6,
      zipRest: response.consignee?.zip4RestUs,
    };
  }
  private getBillTo(response: GetManualRatesPreviewResp): CorrectionsCustomerRespModel {
    return {
      address: response.billTo?.address,
      city: response.billTo?.city,
      chrgsResp: MatchedPartyTypeCd.BILL_TO_INB,
      customerNumber: response.billTo?.cisCustNbr,
      madCode: response.billTo?.asMatchedMadCd,
      country: response.billTo?.countryCd,
      name1: response.billTo?.name1,
      name2: response.billTo?.name2,
      state: response.billTo?.stateCd,
      zip: response.billTo?.zip6,
      zipRest: response.billTo?.zip4RestUs,
    };
  }

  private getDiscounts(response: GetManualRatesPreviewResp): CorrectionsDiscountAmountRespModel {
    const discountItem = response?.manualRatingPro?.miscellaneousLineItems?.find(item => item.lineTypeCd === MiscLineItemCd.DISC_LN);
    if(!discountItem) return new CorrectionsDiscountAmountRespModel();

    return {
      percentage: discountItem.prepaidPercentage,
      description: discountItem.description,
      amount: discountItem.amount
    };
  }
  
  private getCommodities(response: GetManualRatesPreviewResp): CorrectionsCommoditiesRespModel[] {
    return response.manualRatingPro.commodities.map(commodity => {
      return {
        ...new CorrectionsCommoditiesRespModel(),
        pieces: commodity.piecesCount,
        pkg: commodity.packageCd,
        hazmat: commodity.hazardousMtInd,
        description: commodity.description,
        nmfc: commodity.nmfcItemCd,
        class: commodity.classType,
        weight: commodity.weightLbs,
        rate: commodity.tariffsRate,
        minimumChargeInd: commodity.minimumChargeInd,
        amount: commodity.amount,
        sequenceNbr: commodity.sequenceNbr,
        id: this.commoditiesMapperService.getId(commodity)
      } as CorrectionsCommoditiesRespModel;
    });
  }

  private getAccesorials(response: GetManualRatesPreviewResp): CorrectionsAccessorialRespModel[] {
    return response.manualRatingPro.accessorials?.map(acc => {
      return {
        ...new CorrectionsAccessorialRespModel(),
        sequenceNbr: acc.sequenceNbr,
        code: acc.accessorialCd,
        description: acc.description,
        rate: acc.tariffsRate,
        amount: acc.amount,
        id: this.accessorialsMapperService.getId(acc)
      } as CorrectionsAccessorialRespModel;
    });
  }

  private getRevenues(response: GetManualRatesPreviewResp): CorrectionsPreviewRevenuesModel {
    return {
      totalRevenue: response.manualRatingPro?.ratingInformation.ctsRevenueAmount,
      advanceRevenue: response.manualRatingPro?.advanceRevenueAmount,
      beyondRevenue: response.manualRatingPro?.beyondRevenueAmount
    };
  }

  private getTotals(response: GetManualRatesPreviewResp): CorrectionsPreviewTotalsModel {
    return {
      totalPcs: response.manualRatingPro.commodities.reduce((acumulador, commodity) => acumulador + (commodity.piecesCount ?? 0), 0),
      totalWeight: response.manualRatingPro.ratingInformation.reducedWeight,
      totalCharges: response.manualRatingPro.ratingInformation.totalChargeAmount,
      totalPercentage: 99999999999,
    };
  }
}
