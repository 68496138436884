import { Injectable } from '@angular/core';
import { HandleErrorApiService } from '@shared/utils/apis/error/handle-error-api.service';
import {
  GetShipmentForPotentialCorrectionResp,
} from '@xpo-ltl-2.0/sdk-billcorrection';
import { GetRatingInformationPath, GetRatingInformationResp, ShipmentApiService } from '@xpo-ltl-2.0/sdk-shipment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ShipmentDetailApiService {
  constructor(
    private shipmentApiService: ShipmentApiService,
    private handleErrorApiService: HandleErrorApiService
  ) {}

  getRatingInformation(
    queryParams: GetRatingInformationPath
  ): Observable<GetRatingInformationResp> {
    /* the endpoint doesnt returns the Envelope type, so it only returns the GetRatingInformationResp,
    thats why we need to set it as any
     */
    return this.shipmentApiService.getRatingInformation(queryParams).pipe(
      map(response => response.data || response as any),
      catchError((response) => {
        console.error(response);
        return throwError(this.handleErrorApiService.handleError<GetShipmentForPotentialCorrectionResp>(response));
      })
    );
  }
}
