import { CorrectionRequestStatusCd } from "@xpo-ltl/sdk-common";

export enum CorrectionsRequestInfoFormEnum {
  categoryAndReason = 'categoryAndReason',
  ccEmailTo = 'ccEmailTo',
  correctionQueue = 'correctionQueue',
  expectedDelivery = 'expectedDelivery',
  deliveryDate = 'deliveryDate',
  isDriverWaiting = 'isDriverWaiting',
  isSaveQueueAsDefault = 'isSaveQueueAsDefault',
  loggedUserEmail = 'loggedUserEmail',
  pickupDate = 'pickupDate',
  requestDateTime = 'requestDateTime',
  requester = 'requester',
  requesterComments = 'requesterComments',
  sendCopyToMyEmail = 'sendCopyToMyEmail',
};

export enum CorrectionsRequestInfoTitlesEnum {
  title = 'Corrections Request Info'
};

export enum CorrectionsRequestInfoLabel {
  requester = 'REQUESTER',
  requestDateTime = 'Request Date/Time',
  categoryAndReason = 'Category And Reason',
  correctionQueue = 'QUEUE',
  pickupDate = 'PICKUP DATE',
  proNumber = 'PRO Number',
  billClass = 'BILL CLASS',
  auditor = 'AUDITOR',
  correctionsApplied = 'CORRECTIONS APPLIED',
  requestStatus = 'REQUEST STATUS',
  reason = 'REASON',
  requesterComments = 'REQUESTER COMMENTS',
  auditorComments = 'AUDITOR COMMENTS',
  authorityComments = 'AUTHORITY COMMENTS',
  ccdEmail = `CC'd Emails`,
  wasPriority = 'Was this a priority Correction?',
  wasAutorated = 'Was the correction AutoRated?',
  wasRequested = 'Was Invoice Requested to be Printed?',
  expectedDelivery = 'STANDARD SERVICE DUE DATE',
  deliveryDate = 'DELIVERY DATE',
  isDriverWaiting = 'Is driver waiting for updated delivery receipt?',
  isSaveQueueAsDefault = 'Save queue as default',
  emailTo = 'CC EMAIL TO',
  emailButton = 'Edit Email List',
  timeDateCritical = 'Time Date Critical Correction?',
  switchToTdc = 'Switching to a TDC correction will result in the loss of any change made on the current form.',
  switchFromTdc = 'Switching back to the non-TDC correction will result in the loss of any change made on the current form.'
};

export enum CorrectionsRequestInfoIdsEnum {
  categoryAndReason = 'categoryAndReasonRequestInfo',
  correctionQueue = 'correctionQueueRequestInfo',
  deliveryDate = 'deliveryDateRequestInfo',
  emailTo = 'emailToRequestInfo',
  emailButton = 'emailButtonRequestInfo',
  expectedDelivery = 'expectedDeliveryRequestInfo',
  isDriverWaiting = 'isDriverWaitingRequestInfo',
  requester = 'requesterRequestInfo',
  requestDateTime = 'requestDateTimeRequestInfo',
  pickupDate = 'pickupDateRequestInfo',
};

export enum CorrectionsRequestInfoTooltipEnum {
  title = 'Is driver waiting for updated delivery receipt?',
  message = `
    If the request should be expedited so the driver can get a corrected delivery receipt
    before leaving the service center, for example, select Yes. This could happen on a driver
    collect shipment for a consignee who doesn't have credit.`
};

export enum CorrectionsRequestInfoFormRulesEnum {
  defaultQueue = 'GEN CORR'
};

export enum CorrectionsRequestInfoCssClassesEnum {
  default = 'default-content',
  tdc = 'tdc-content',
};

export enum RequestStatusEnum {
  applied = CorrectionRequestStatusCd.APPLIED,
  approved = CorrectionRequestStatusCd.APPROVED,
  declined = CorrectionRequestStatusCd.DECLINED,
  locked = CorrectionRequestStatusCd.LOCKED,
  manuallyApplied = CorrectionRequestStatusCd.MANUALLY_APPLIED,
  oracleApplied = CorrectionRequestStatusCd.ORACLE_APPLIED,
  pending = CorrectionRequestStatusCd.PENDING,
  saved = CorrectionRequestStatusCd.SAVED,
};
