export enum CorrectionsBillToFormEnum {
    deleteBillTo = 'deleteBillTo',
    customerNumber = 'customerNumber',
    madCode = 'madCode',
    nameOne = 'nameOne',
    nameTwo = 'nameTwo',
    address = 'address',
    city = 'city',
    state = 'state',
    zipCode = 'zipCode',
    zipCodeComplementation = 'zipCodeComplementation',
    country = 'country',
    chrgsResp = 'chrgsResp',
    deleteBtn = 'deleteBtn',
};

export enum CorrectionsFormBillToLabelEnum {
    deleteBillTo = 'Delete Bill To/Third Party',
    madCode = 'MAD CODE',
    name1 = 'NAME 1',
    name2 = 'NAME 2',
    address = 'ADDRESS',
    country = 'COUNTRY',
    chrgsResp = 'Responsible',
    cityStateZip = 'CITY, STATE, ZIP',
    city = 'CITY',
    state = 'STATE',
    zip = 'ZIP'
};

export enum CorrectionsFormBillToValuesEnum {
    name1EmptyBillToConsignee = 'SAME AS CONSIGNEE',
    name1EmptyBillToShipper = 'SAME AS SHIPPER',
};

export enum CorrectionsFormBillToTitlesEnum {
    title = 'Bill To / Third Party'
};


export enum CorrectionsBillToFormIdsEnum {
  address = 'addressBillToId',
  city = 'cityBillToId',
  country = 'countryBillToId',
  madCode = 'madCodeBillToId',
  nameOne = 'nameOneBillToId',
  nameTwo = 'nameTwoBillToId',
  state = 'stateBillToId',
  undoBtn = 'undoBtnBillToId',
  zipCode = 'zipCodeBillToId',
  zipCodeComplementation = 'zipCodeComplementationBillToId',
}