import { Pipe, PipeTransform } from '@angular/core';
import { CorrectionsRateContentDetailsLabelsEnum } from '@shared/enums/corrections/corrections-common.enum';
import { CorrectionsPreviewRatesRouteModel } from '@shared/models/corrections/preview-rates/corrections-preview-rates.model';
import { DateXpoPipe } from '../date-xpo/date-xpo.pipe';

@Pipe({
  name: 'shipmentRoute'
})
export class ShipmentRoutePipe implements PipeTransform {
  constructor(
    private dateXpoPipe: DateXpoPipe
  ) {}

  transform(route: CorrectionsPreviewRatesRouteModel | undefined, isAdvanceScac: boolean): string {
    if (!route || (!route.advanceScac?.scacCode && !route.beyondScac?.scacCode)) {
      return '';
    }

    else if (isAdvanceScac && route.advanceScac) {
      const { scacCode, date, proNumber } = route.advanceScac;
      return `${scacCode || ''} ${this.dateXpoPipe.transform(date) || ''} ${proNumber || ''}`;
    }

    const { fromTerminalSic, scacCode } = route.beyondScac || {};
    return `${CorrectionsRateContentDetailsLabelsEnum.cnwy} ${fromTerminalSic || ''} ${scacCode || ''}`;
  }
}
