import { Injectable } from '@angular/core';
import { UpdateQueueForCorrectionRequestsResp, UpdateQueueForCorrectionRequestsRqst } from '@xpo-ltl-2.0/sdk-billcorrection';

@Injectable()
export class CorrectionsRequestUpdateQueueMapperService {
  
  getRequest(correctionRequestInstIds: number[], queueName: string): UpdateQueueForCorrectionRequestsRqst {
    const request = new UpdateQueueForCorrectionRequestsRqst();
    request.correctionRequestInstIds = correctionRequestInstIds;
    request.queueName = queueName;
    return request;
  }
}
