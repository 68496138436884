// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  enableDevTools: true,
  firebase: {
    apiKey: 'AIzaSyBIo-Gnt7bOdJLouRxWEeDzmBkI-h90JjY',
    authDomain: 'xpo-ltl-dev-cf3b6.firebaseapp.com',
    databaseURL: 'https://xpo-ltl-dev-cf3b6.firebaseio.com',
    projectId: 'xpo-ltl-dev',
    storageBucket: 'xpo-ltl-dev.appspot.com',
    messagingSenderId: '339239167082',
    appId: '1:339239167082:web:248f75d678c609c1f29613',
  },
};
