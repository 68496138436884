export enum ReleaseNotesIdsEnum {
  viewNow = 'viewNow',
  dismiss = 'dismiss'
}

export enum ReleaseNotesTitlesEnum {
  title = 'Edge Corrections Update',
  text = 'A new version of Edge Corrections has been released since the last time you logged on.',
  textTwo = 'Check out the Release Notes for details!',
  viewNow = 'View Now',
  dismiss = 'Dismiss',
}
