import { createAction, props } from '@ngrx/store';
import { CorrectionSideNavSectionEnum } from '@shared/enums/corrections/corrections-sidenav.enum';

export abstract class SideNavActions {
  static clearSidenavOpenSection = createAction('[CorrectionsActions] Clear sidenavOpen open section');
  static setSidenavOpenSection = createAction(
    '[CorrectionsActions] Set sidenavOpen open section',
    props<{ sidenavOpenSection: CorrectionSideNavSectionEnum | undefined }>()
  );
}
