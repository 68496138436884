import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { UserPreferenceActions } from '../actions/user-preference.actions';
import { UserPreferenceSelectors } from '../selectors/user-preference.selector';
import { UserPreferenceComponentNameType, UserPreferenceResultType } from '@shared/types/general/user-preference.types';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { UserPreferenceConfig, UserPreferenceModel } from '@shared/models/shared/user-preference.model';

export interface ReleaseNotes {
  buildVersion: string;
}

@Injectable()
export class UserPreferenceHandlerService {
  correctionsDefaultQueuePreferencesSelector$ = this.store$.pipe(select(UserPreferenceSelectors.correctionsDefaultQueuePreferences));
  correctionsBuildVersionPreferencesSelector$ = this.store$.pipe(select(UserPreferenceSelectors.correctionsBuildVersionPreferences));
  correctionsBuildVersionPreferencesSuccess$ = this.actions$.pipe(
    ofType(UserPreferenceActions.getBuildVersionPreferenceSuccess),
    map((action) => action.preferences)
  );

  constructor(public store$: Store, public actions$: Actions) {}

  getDefaultQueuePreference(componentName: UserPreferenceComponentNameType): void {
    this.store$.dispatch(UserPreferenceActions.getDefaultQueuePreference({ componentName }));
  }

  updateDefaultQueuePreference(componentName: UserPreferenceComponentNameType, preferences: UserPreferenceResultType): void {
    this.store$.dispatch(UserPreferenceActions.updateDefaultQueuePreference({ componentName, preferences }));
  }

  getBuildVersionPreference(componentName: UserPreferenceComponentNameType): void {
    this.store$.dispatch(UserPreferenceActions.getBuildVersionPreference({ componentName }));
  }

  updateBuildVersionPreference(componentName: UserPreferenceComponentNameType, preferences: UserPreferenceResultType): void {
    this.store$.dispatch(UserPreferenceActions.updateBuildVersionPreference({ componentName, preferences }));
  }

  doNewReleaseNotesExist(currentBuildVersion: string): Observable<boolean> {
    return this.correctionsBuildVersionPreferencesSelector$.pipe(
      map((preference: UserPreferenceModel | undefined) => preference?.buildVersion !== currentBuildVersion),
    );
  }

  dismissNewReleaseNotes(componentName: UserPreferenceComponentNameType, buildVersion: string): void {
    let previousUserPreferences: UserPreferenceModel | undefined;
    this.correctionsBuildVersionPreferencesSelector$.pipe(take(1)).subscribe((userPreferences) => {
      previousUserPreferences = userPreferences;
    });
    const preferences: UserPreferenceModel = previousUserPreferences
      ? { ...previousUserPreferences, buildVersion }
      : {
          buildVersion
        };
    this.updateBuildVersionPreference(componentName, preferences);
  }
}
