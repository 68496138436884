import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MetadataUiViewEnum } from '@shared/enums/common-data/metadata.enum';
import { RequestActionTypeCd } from '@shared/enums/common-data/request-action-type.enum';
import { ScreenEnum } from '@shared/enums/common-data/screen.enum';
import { CorrectionsAccountsReceivableMapperService } from '@shared/mappers/corrections/accounts-receivable/corrections-accounts-receivable-mapper.service';
import { CorrectionsAssociatedBillToMapperService } from '@shared/mappers/corrections/associated-bill-to/corrections-associated-bill-to-mapper.service';
import { CorrectionsCorrectedRequestMapperService } from '@shared/mappers/corrections/corrected-request/corrections-corrected-request-mapper.service';
import { CorrectionsCorrectedUpdateValuesService } from '@shared/mappers/corrections/corrected-request/update-values/corrections-corrected-update-values.service';
import { CorrectionsCreateRequestMapperService } from '@shared/mappers/corrections/create-request/corrections-create-request-mapper.service';
import { CorrectionsCreateRequestTdcMapperService } from '@shared/mappers/corrections/create-request/tdc/corrections-create-request-tdc-mapper.service';
import { CorrectionsCreditStatusMapperService } from '@shared/mappers/corrections/credit-status/corrections-credit-status-mapper.service';
import { CorrectionsDetailMapperService } from '@shared/mappers/corrections/detail/corrections-detail-mapper.service';
import { CorrectionsListMetadataMapperService } from '@shared/mappers/corrections/list-metadata/corrections-list-metadata-mapper.service';
import { CorrectionsAutoRatesMetadataMapperService } from '@shared/mappers/corrections/preview-rates-metadata/auto-rates/corrections-auto-rates-metadata-mapper.service';
import { CorrectionsManualRatesMetadataMapperService } from '@shared/mappers/corrections/preview-rates-metadata/manual-rates/corrections-manual-rates-metadata-mapper.service';
import { CorrectionsAutoRatesRequestMapperService } from '@shared/mappers/corrections/preview-rates-request/auto-rates/corrections-auto-rates-request-mapper.service';
import { CorrectionsManualRatesRequestMapperService } from '@shared/mappers/corrections/preview-rates-request/manual-rates/corrections-manual-rates-request-mapper.service';
import { CorrectionsRequestAndHistoryMapperService } from '@shared/mappers/corrections/request-and-history/corrections-request-and-history-mapper.service';
import { CorrectionsRequestUnlockUpdateStatusMapperService } from '@shared/mappers/corrections/request-lock-update-status/corrections-request-unlock-update-status-mapper.service';
import { CorrectionsRequestQueueSummariesMapperService } from '@shared/mappers/corrections/request-queue-summaries/corrections-request-queue-summaries-mapper.service';
import { CorrectionsRequestUpdateQueueMapperService } from '@shared/mappers/corrections/request-update-queue/corrections-request-update-queue-mapper.service';
import { RevenuesCorrectedMapperService } from '@shared/mappers/corrections/revenues/corrected/revenues-corrected-mapper.service';
import { ShipmentDetailMapperService } from '@shared/mappers/corrections/shipment-detail/shipment-detail-mapper.service';
import { CorrectionsBillChargesModel } from '@shared/models/corrections/bill-payment/corrections-bill-charges.model';
import { CorrectionsDetailModel } from '@shared/models/corrections/corrections-detail.model';
import { CorrectionsFormCreateRequestResponseModel, CorrectionsFormValueModel } from '@shared/models/corrections/corrections-form/corrections-form.model';
import { CorrectionsRevenuesModel } from '@shared/models/corrections/corrections-form/revenues/corrections-revenues.model';
import { CorrectionsListMetadataModel } from '@shared/models/corrections/corrections-list-metadata.model';
import { CorrectionsPreviewRatesModel } from '@shared/models/corrections/preview-rates/corrections-preview-rates.model';
import { ErrorModel } from '@shared/models/shared/error.model';
import { CreateGetRequestMapperParams } from '@shared/models/shared/mappers/create-request-mapper.model';
import { AccountsReceivableDataApiService } from '@shared/services/apis/accounts-receivable/accounts-receivable-data-api.service';
import { CustomerDataApiService } from '@shared/services/apis/customer/customer-data-api.service';
import { DataServicesDataApiService } from '@shared/services/apis/data-services/data-services-data-api.service';
import { CorrectionsShipmentApiService } from '@shared/services/apis/shipment/correctons-shipment-api.service';
import { ShipmentDetailApiService } from '@shared/services/apis/shipment/shipment-detail-api.service';
import { GetAutoRatesPreviewResp, GetManualRatesPreviewResp } from '@xpo-ltl-2.0/sdk-billcorrection';
import { GetCustomerSicOverridePath, GetCustomerSicOverrideResp } from '@xpo-ltl/sdk-dataservices';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, delay, exhaustMap, map, switchMap, take } from 'rxjs/operators';
import { CorrectionsActions } from '../actions/corrections.actions';

@Injectable()
export class CorrectionsEffects {
  getDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getDetails),
      exhaustMap(({ proNbr, errorActionFn }) => {
        const queryParams = this.correctionsDetailMapperService.getRequest({ proNbr });
        return this.shipmentService.getShipment(queryParams, errorActionFn).pipe(
          switchMap((response) => this.correctionsDetailMapperService.getMappedData(response)),
          map((correctionsDetail: CorrectionsDetailModel) =>
            CorrectionsActions.getDetailsSuccess({ correctionsDetail })
          ),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.getDetailsFail({ errors })))
        );
      })
    )
  );

  getPendingCorrectionDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getPendingCorrectionDetails),
      exhaustMap(({ pendingRequestStore, errorActionFn }) => {
        const { correctionRequestInstId, currentScreen } = pendingRequestStore;
        const shipmentApiQueryParams = this.correctionsDetailMapperService.getRequest(pendingRequestStore);
        const correctedShipmentApiQueryParams = this.correctionsCorrectedRequestMapperService.getRequest(correctionRequestInstId as number, currentScreen as ScreenEnum);
        return forkJoin([
          this.shipmentService.getShipment(shipmentApiQueryParams, errorActionFn),
          this.shipmentService.getCorrectedShipment(correctedShipmentApiQueryParams),
        ]).pipe(
          map(([shipmentResponse, correctedShipmentResponse]) => {
            let originalDetail: CorrectionsDetailModel | undefined;
            this.correctionsDetailMapperService
              .getMappedData(shipmentResponse)
              .pipe(take(1))
              .subscribe((orgDetail) => (originalDetail = orgDetail));
            const correctedDetail = this.correctionsCorrectedRequestMapperService.getMappedData(correctedShipmentResponse, shipmentResponse);
            const correctedFormFields = this.correctionsCorrectedUpdateValuesService.getCorrectedFormFields(correctedShipmentResponse, shipmentResponse);
            this.correctionsCorrectedRequestMapperService.setLockedInd(correctedShipmentResponse);

            return {
              originalDetail,
              correctedDetail,
              correctedFormFields,
            };
          }),
          map(({ originalDetail, correctedDetail, correctedFormFields }) => {
            return CorrectionsActions.getPendingCorrectionDetailsSuccess({
              originalDetail,
              correctedDetail,
              correctedFormFields,
            });
          }),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.getPendingCorrectionDetailsFail({ errors })))
        );
      })
    )
  );

  getBillCharges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getBillCharges),
      exhaustMap(({ billChargesGetVariables }) => {
        const { proNbr, pickupDate, ctsRev, charges } = billChargesGetVariables;
        const queryParams = this.correctionsAccountsReceivableMapperService.getRequest(proNbr, pickupDate);
        return this.accountsReceivableDataApiService.listArPaymentDetailsByPro(queryParams).pipe(
          map((response) => this.correctionsAccountsReceivableMapperService.getBillChargesFromApi(response, ctsRev, charges)),
          map((billCharges: CorrectionsBillChargesModel) => CorrectionsActions.getBillChargesSuccess({ billCharges })),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.getBillChargesFail({ errors })))
        );
      })
    )
  );

  getCustomerSicOverride$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getCustomerSicOverride),
      exhaustMap(({ customerNbr }) => {
        const pathParams = new GetCustomerSicOverridePath();
        pathParams.customerNbr = customerNbr;
        return this.dataServicesDataApiService.getCustomerSicOverride(pathParams).pipe(
          map((customerSicOverride: GetCustomerSicOverrideResp) =>
            CorrectionsActions.getCustomerSicOverrideSuccess({ customerSicOverride })
          ),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.getCustomerSicOverrideFail({ errors })))
        );
      })
    )
  );

  getAutoRatePreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getAutoRatePreview),
      exhaustMap(({ proNbr, shipInstId, correctionsForm }) => {
        return this.getAutoRatePreview(proNbr, shipInstId, correctionsForm).pipe(
          map((response) => this.correctionsAutoRatesMetadataMapperService.getMappedDataForAuto(response)),
          map((autoRatesPreview: CorrectionsPreviewRatesModel | undefined) => CorrectionsActions.getAutoRatePreviewSuccess({ autoRatesPreview })),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.getAutoRatePreviewFail({ errors })))
        );
      })
    )
  );

  getManualRatePreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getManualRatePreview),
      exhaustMap(({ proNbr, shipInstId, correctionsForm }) => {
        return this.getManualRatePreview(proNbr, shipInstId, correctionsForm).pipe(
          map((response) => this.correctionsManualRatesMetadataMapperService.getMappedDataForManual(response)),
          map((manualRatesPreview: CorrectionsPreviewRatesModel) => CorrectionsActions.getManualRatePreviewSuccess({ manualRatesPreview })),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.getManualRatePreviewFail({ errors })))
        );
      })
    )
  );

  getCorrectedRevenues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getCorrectedRevenues),
      exhaustMap(({ correctedRevenueModel }) => {        
        if (correctedRevenueModel.manualRateInd) {
          return this.getManualRatePreview(correctedRevenueModel.proNbr, correctedRevenueModel.shipInstId, correctedRevenueModel.correctionsForm).pipe(
            map((response) => this.revenuesCorrectedMapperService.getMappedDataFromPreviewManualRate(response)),
            map(revenuesResp => CorrectionsActions.getCorrectedRevenuesSuccess({ revenuesResp })),
            catchError((errors: ErrorModel[]) => of(CorrectionsActions.getCorrectedRevenuesFail({ errors })))
          );
        }

        return this.getAutoRatePreview(correctedRevenueModel.proNbr, correctedRevenueModel.shipInstId, correctedRevenueModel.correctionsForm).pipe(
          map((response) => this.revenuesCorrectedMapperService.getMappedDataFromPreviewAutoRate(response)),
          map(revenuesResp => CorrectionsActions.getCorrectedRevenuesSuccess({ revenuesResp })),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.getCorrectedRevenuesFail({ errors })))
        );
      })
    )
  );

  getListCorrectionsEditMetaData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getListCorrectionsEditMetaData),
      exhaustMap(({ metadataParams }) => {
        const { tdcInd, billClass, contentGroupInd, loadingOverlayEnabled } = metadataParams;
        const queryParamsEdit = this.correctionsListMetadataMapperService.getRequestForListCorrectionsMetaDataForEdit(MetadataUiViewEnum.edit, tdcInd, contentGroupInd, billClass);
        return this.shipmentService.getListCorrectionsMetaData(queryParamsEdit, { loadingOverlayEnabled }).pipe(
          map((response) => this.correctionsListMetadataMapperService.getMappedData(response)),
          map((correctionsEditMetadata: CorrectionsListMetadataModel) =>
            CorrectionsActions.getListCorrectionsEditMetaDataSuccess({ correctionsEditMetadata })
          ),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.getListCorrectionsEditMetaDataFail({ errors })))
        );
      })
    )
  );

  getListCorrectionsAuditMetaData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getListCorrectionsAuditMetaData),
      exhaustMap(({ loadingOverlayEnabled }) => {
        const queryParamsAudit = this.correctionsListMetadataMapperService.getRequestForListCorrectionsMetaData(MetadataUiViewEnum.audit);
        return this.shipmentService.getListCorrectionsMetaData(queryParamsAudit, { loadingOverlayEnabled }).pipe(
          map((response) => this.correctionsListMetadataMapperService.getMappedData(response)),
          map((correctionsAuditMetadata: CorrectionsListMetadataModel) =>
            CorrectionsActions.getListCorrectionsAuditMetaDataSuccess({ correctionsAuditMetadata })
          ),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.getListCorrectionsAuditMetaDataFail({ errors })))
        );
      })
    )
  );

  getListCorrectionsReassignMetaData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getListCorrectionsReassignMetaData),
      exhaustMap(({ loadingOverlayEnabled }) => {
        const queryParamsReassign = this.correctionsListMetadataMapperService.getRequestForListCorrectionsMetaData(MetadataUiViewEnum.reassign);
        return this.shipmentService.getListCorrectionsMetaData(queryParamsReassign, { loadingOverlayEnabled }).pipe(
          map((response) => this.correctionsListMetadataMapperService.getMappedData(response)),
          map((correctionsReassignMetadata: CorrectionsListMetadataModel) =>
            CorrectionsActions.getListCorrectionsReassignMetaDataSuccess({ correctionsReassignMetadata })
          ),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.getListCorrectionsReassignMetaDataFail({ errors })))
        );
      })
    )
  );

  getRequestAndHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getRequestAndHistory),
      exhaustMap(({ queryParams }) => {
        return this.shipmentService.getRequestAndHistory(queryParams).pipe(
          map((response) => this.correctionsRequestAndHistoryService.getRequestAndHistoryFromApi(response)),
          map((correctionsRequestAndHistory) =>
            CorrectionsActions.getRequestAndHistorySuccess({ correctionsRequestAndHistory })
          ),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.getRequestAndHistoryFail({ errors })))
        );
      })
    )
  );

  getRequestAuditInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getRequestAuditInfo),
      exhaustMap(({ requestId, includeHistoryInd }) => {
        const queryParams = this.correctionsRequestAndHistoryService.getRequestForRequestAuditInfo(
          requestId,
          includeHistoryInd
        );
        return this.shipmentService.getRequestAndHistory(queryParams).pipe(
          map((response) => this.correctionsRequestAndHistoryService.getRequestAuditInfoFromApi(response)),
          map((correctionsRequestAndHistory) =>
            CorrectionsActions.getRequestAuditInfoSuccess({ correctionsRequestAndHistory })
          ),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.getRequestAuditInfoFail({ errors })))
        );
      })
    )
  );

  getAssociatedBillTo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getAssociatedBillTo),
      exhaustMap(({ customerNumber, billCd, madCode }) => {
        const queryParams = this.correctionsAssociatedBillToMapperService.getRequest(customerNumber);
        return this.customerDataApiService.getInvoicingInstructions(queryParams).pipe(
          map((response) => this.correctionsAssociatedBillToMapperService.getMappedData(response, billCd, madCode)),
          map((associatedBillTo) => CorrectionsActions.getAssociatedBillToSuccess({ associatedBillTo })),
          catchError((errors) => of(CorrectionsActions.getAssociatedBillToFail({ errors })))
        );
      })
    )
  );

  getCustomerCreditStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getCustomerCreditStatus),
      exhaustMap(({ customerNumber }) => {
        const queryParams = this.correctionsCreditStatusMapperService.getRequest(customerNumber);
        return this.customerDataApiService.getCreditStatus(queryParams).pipe(
          map((response) => this.correctionsCreditStatusMapperService.getMappedData(response)),
          map((creditStatus) => CorrectionsActions.getCustomerCreditStatusSuccess({ creditStatus })),
          catchError((errors) => of(CorrectionsActions.getCustomerCreditStatusFail({ errors })))
        );
      })
    )
  );

  getRequestQueueSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getRequestQueueSummaries),
      exhaustMap(({ loadingOverlayEnabled }) => {
        return this.shipmentService.getRequestQueueSummaries({ loadingOverlayEnabled }).pipe(
          map((response) => this.requestQueueSummariesMapperService.getRequestQueueSummariesFromApi(response)),
          map((correctionsRequestQueueSummaries) =>
            CorrectionsActions.getRequestQueueSummariesSuccess({ correctionsRequestQueueSummaries })
          ),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.getRequestQueueSummariesFail({ errors })))
        );
      })
    )
  );

  getRatingInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.getRatingInformation),
      exhaustMap(({ shipmentInstId }) => {
        return this.shipmentDetailService.getRatingInformation({ shipmentInstId }).pipe(
          map((response) => this.shipmentDetailMapperService.getMappedData(response)),
          map((ratingInformationResp) =>
            CorrectionsActions.getRatingInformationSuccess({ ratingInformationResp })
          ),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.getRatingInformationFail({ errors })))
        );
      })
    )
  );

  deleteCorrections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.deleteCorrections),
      exhaustMap(({ requestIds }) => {
        const queryParams = this.correctionsRequestAndHistoryService.getRequestForDeleteCorrectionRequests(requestIds);
        return this.shipmentService.deleteCorrectionRequests(queryParams).pipe(
          map((_) => CorrectionsActions.deleteCorrectionsSuccess()),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.deleteCorrectionsFail({ errors })))
        );
      })
    )
  );

  submitRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.submitRequest),
      exhaustMap(({ correctionsForm, originalForm }) => {
        return this.submitRequestCommon(correctionsForm, originalForm, RequestActionTypeCd.submit).pipe(
          map((response) => CorrectionsActions.submitRequestSuccess({ response })),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.submitRequestFail({ errors })))
        )
      }),
    )
  );

  submitTdcRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.submitTdcRequest),
      exhaustMap(({ tdcCorrectionsForm }) => {
        const queryParams = this.correctionsCreateRequestTdcMapperService.getRequest(tdcCorrectionsForm, RequestActionTypeCd.submit);
        return this.shipmentService.submitRequest(queryParams).pipe(
          map((apiResponse) => this.correctionsCreateRequestTdcMapperService.getResponse(apiResponse)),
          map((response) => CorrectionsActions.submitTdcRequestSuccess({ response })),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.submitTdcRequestFail({ errors })))
        );
      })
    )
  );

  submitAuditorRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.submitAuditorRequest),
      exhaustMap(({ correctionsForm, originalForm, requestActionTypeCd }) => {
        return this.submitRequestCommon(correctionsForm, originalForm, requestActionTypeCd).pipe(
          map((response) => CorrectionsActions.submitAuditorRequestSuccess({ response })),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.submitAuditorRequestFail({ errors })))
        )
      }),
    )
  );

  updateUnlockCorrectionRequestStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.updateUnlockCorrectionRequestStatus),
      exhaustMap(({ correctionRequestInstIds }) => {
        const request = this.correctionsRequestUnlockUpdateStatusMapperService.getRequest(correctionRequestInstIds);
        return this.shipmentService.unlockCorrectionRequestStatus(request).pipe(
          map(() => CorrectionsActions.updateUnlockCorrectionRequestStatusSuccess()),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.updateUnlockCorrectionRequestStatusFail({ errors })))
        );
      })
    )
  );

  updateQueueForCorrectionRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.updateQueueForCorrectionRequests),
      exhaustMap(({ correctionRequestInstIds, queueName }) => {
        const request = this.correctionsRequestUpdateQueueMapperService.getRequest(
          correctionRequestInstIds,
          queueName
        );
        return this.shipmentService.updateQueueForCorrectionRequests(request).pipe(
          map((response) => CorrectionsActions.updateQueueForCorrectionRequestsSuccess({ response: response.message })),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.updateQueueForCorrectionRequestsFail({ errors })))
        );
      })
    )
  );

  resubmitRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.resubmitRequest),
      exhaustMap(({ correctionsForm, originalForm }) => {
        return this.submitRequestCommon(correctionsForm, originalForm, RequestActionTypeCd.reSubmit).pipe(
          map((response) => CorrectionsActions.resubmitRequestSuccess({ response })),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.resubmitRequestFail({ errors })))
        )
      }),
    )
  );

  resubmitTdcRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.resubmitTdcRequest),
      exhaustMap(({ tdcCorrectionsForm }) => {
        const queryParams = this.correctionsCreateRequestTdcMapperService.getRequest(tdcCorrectionsForm, RequestActionTypeCd.reSubmit);
        return this.shipmentService.submitRequest(queryParams).pipe(
          map((apiResponse) => this.correctionsCreateRequestTdcMapperService.getResponse(apiResponse)),
          map((response) => CorrectionsActions.resubmitTdcRequestSuccess({ response })),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.resubmitTdcRequestFail({ errors })))
        );
      })
    )
  );

  submitTdcAuditorRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorrectionsActions.submitTdcAuditorRequest),
      exhaustMap(({ tdcCorrectionsForm, requestActionTypeCd }) => {
        const queryParams = this.correctionsCreateRequestTdcMapperService.getRequest(tdcCorrectionsForm, requestActionTypeCd);
        return this.shipmentService.submitRequest(queryParams).pipe(
          map((apiResponse) => this.correctionsCreateRequestTdcMapperService.getResponse(apiResponse)),
          map((response) => CorrectionsActions.submitTdcAuditorRequestSuccess({ response })),
          catchError((errors: ErrorModel[]) => of(CorrectionsActions.submitTdcAuditorRequestFail({ errors })))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private correctionsAccountsReceivableMapperService: CorrectionsAccountsReceivableMapperService,
    private correctionsAssociatedBillToMapperService: CorrectionsAssociatedBillToMapperService,
    private correctionsCorrectedRequestMapperService: CorrectionsCorrectedRequestMapperService,
    private correctionsCorrectedUpdateValuesService: CorrectionsCorrectedUpdateValuesService,
    private correctionsCreditStatusMapperService: CorrectionsCreditStatusMapperService,
    private correctionsCreateRequestMapperService: CorrectionsCreateRequestMapperService,
    private correctionsCreateRequestTdcMapperService: CorrectionsCreateRequestTdcMapperService,
    private correctionsDetailMapperService: CorrectionsDetailMapperService,
    private shipmentDetailMapperService: ShipmentDetailMapperService,
    private correctionsListMetadataMapperService: CorrectionsListMetadataMapperService,
    private correctionsAutoRatesRequestMapperService: CorrectionsAutoRatesRequestMapperService,
    private correctionsAutoRatesMetadataMapperService: CorrectionsAutoRatesMetadataMapperService,
    private correctionsManualRatesRequestMapperService: CorrectionsManualRatesRequestMapperService,
    private correctionsManualRatesMetadataMapperService: CorrectionsManualRatesMetadataMapperService,
    private correctionsRequestAndHistoryService: CorrectionsRequestAndHistoryMapperService,
    private correctionsRequestUnlockUpdateStatusMapperService: CorrectionsRequestUnlockUpdateStatusMapperService,
    private correctionsRequestUpdateQueueMapperService: CorrectionsRequestUpdateQueueMapperService,
    private requestQueueSummariesMapperService: CorrectionsRequestQueueSummariesMapperService,
    private revenuesCorrectedMapperService: RevenuesCorrectedMapperService,
    private shipmentService: CorrectionsShipmentApiService,
    private shipmentDetailService: ShipmentDetailApiService,
    private customerDataApiService: CustomerDataApiService,
    private accountsReceivableDataApiService: AccountsReceivableDataApiService,
    private dataServicesDataApiService: DataServicesDataApiService
  ) { }

  private submitRequestCommon(correctionsForm: CorrectionsFormValueModel, originalForm: CorrectionsFormValueModel, requestActionTypeCd: RequestActionTypeCd): Observable<CorrectionsFormCreateRequestResponseModel> {
    return this.correctionsCreateRequestMapperService.getBillCharges()
      .pipe(
        // NOTE: This delay is necessary to display the loading spinner because there is another api called before and the spinner is closed so this setTimeout helps to display it again
        delay(0),
        take(1),
        map(totalPaymentAmount => ({ correctionsForm, originalForm, totalPaymentAmount })),
        exhaustMap(({ correctionsForm, originalForm, totalPaymentAmount }) => {
          const createGetRequestMapperParams: CreateGetRequestMapperParams = {
            correctionsForm, originalForm, totalPaymentAmount, requestActionTypeCd
          };

          const queryParams = this.correctionsCreateRequestMapperService.getRequest(createGetRequestMapperParams);
          return this.shipmentService.submitRequest(queryParams).pipe(
            map((apiResponse) => this.correctionsCreateRequestMapperService.getResponse(apiResponse)),
          );
        })
      );
  }

  private getAutoRatePreview(proNbr: string, shipInstId: number, correctionsForm: CorrectionsFormValueModel): Observable<GetAutoRatesPreviewResp> {
    const request = this.correctionsAutoRatesRequestMapperService.getAutoRateRequest(correctionsForm);
    const queryParams = this.correctionsAutoRatesRequestMapperService.getQueryParams(proNbr, shipInstId, correctionsForm);

    return this.shipmentService.getAutoRatePreview(queryParams, request);
  }

  private getManualRatePreview(proNbr: string, shipInstId: number, correctionsForm: CorrectionsFormValueModel): Observable<GetManualRatesPreviewResp> {
    const request = this.correctionsManualRatesRequestMapperService.getManualRateRequest(correctionsForm);
    const queryParams = this.correctionsManualRatesRequestMapperService.getQueryParams(proNbr, shipInstId, correctionsForm);
    return this.shipmentService.getManualRatePreview(queryParams, request);
  }
}
