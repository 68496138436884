import { createSelector } from '@ngrx/store';
import { UserPreferenceReducer, UserPreferenceState } from '../reducer/user-preference.reducer';
import { UserPreferenceEnum } from '@shared/enums/common-data/user-preference.enum';

const correctionsDefaultQueuePreferences = createSelector(
  UserPreferenceReducer.userPreferenceFeatureSelector,
  (state: UserPreferenceState) => state[UserPreferenceEnum.defaultQueue]
);

const correctionsBuildVersionPreferences = createSelector(
  UserPreferenceReducer.userPreferenceFeatureSelector,
  (state: UserPreferenceState) => state[UserPreferenceEnum.buildVersion]
);

export const UserPreferenceSelectors = { correctionsDefaultQueuePreferences, correctionsBuildVersionPreferences };
