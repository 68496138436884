export const CorrectionsMessagesValue = {
  apis: {
    serviceError: `Unfortunately, there seems to be an error.`
  },
  accessorials: {
    errorEmptyOriginalPickupDate: `CorrectionsFormAccessorials, a original pickupDate is needed`,
    errorEmptyCorrectedPickupDate: `CorrectionsFormAccessorials, a corrected pickupDate is needed`,
  },
  associatedBillTo: {
    wrongCollectChargeCdFromConsignee: `Pre-paid or Both Bills Cannot have a Consignee Billto.`,
    wrongChargeCdFromShipper: `Collect Bill Cannot have a Shipper Billto.`,
    shipperWithoutCustomerNumber: `Shipper must be matched with a Madcode to get Invoicing Instructions.`,
    consigneeWithoutCustomerNumber: `Cosignee must be matched with a Madcode to get Invoicing Instructions.`,
  },
  auditorComments: {
    empty: 'Auditor Comments Required',
  },
  chargeCode: {
    emptyChargeCode: `A Charge Code must be entered.`,
  },
  destinationSic: {
    overrideValidation: 'Dest SIC change not allowed for this Consignee.',
  },
  createCorrection: {
    success: {
      title: `Request successfully submitted!`,
      description: (proNbr: string) => `Your request for PRO ${proNbr} was successfully recorded`,
      textButton: `CONTINUE`,
    },
    error: {
      validations: 'Error: Validation Errors Found',
    },
  },
  createHoldCorrection: {
    success: {
      title: `Request successfully submitted!`,
      description: (proNbr: string) => `Your request for PRO ${proNbr} was successfully reassigned to hold queue`,
      textButton: `CONTINUE`,
    },
    error: {
      validations: 'Error: Validation Errors Found',
    },
  },
  createMoreInfoCorrection: {
    success: {
      title: `Request successfully submitted!`,
      description: (proNbr: string) => `Your request for PRO ${proNbr} was successfully submitted as more information needed`,
      textButton: `CONTINUE`,
    },
    error: {
      validations: 'Error: Validation Errors Found',
    },
  },
  updateReassignCorrection: {
    success: {
      title: `Requests successfully reassigned!`,
      textButton: `CONTINUE`,
    },
    error: {
      validations: 'Error: Validation Errors Found',
    },
  },
  updateCommodityHazmatCorrection: {
    title: `Warning`,
    description: `Please update Hazardous Material handling instructions in Shipping Remarks`,
    textButton: `OK`
  },
  createApprovedCorrection: {
    success: {
      title: `Request successfully submitted!`,
      description: (proNbr: string) => `Your request for PRO ${proNbr} was successfully submitted as approved`,
      textButton: `CONTINUE`,
    },
    error: {
      validations: 'Error: Validation Errors Found',
    },
  },
  createNotApprovedCorrection: {
    success: {
      title: `Request successfully submitted!`,
      description: (proNbr: string) => `Your request for PRO ${proNbr} was successfully submitted as unapproved`,
      textButton: `CONTINUE`,
    },
    error: {
      validations: 'Error: Validation Errors Found',
    },
  },
  createDeEscalateCorrection: {
    success: {
      title: `Request successfully de-escalated!`,
      description: (proNbr: string) => `Your request for PRO ${proNbr} was successfully De-escalated`,
      textButton: `CONTINUE`,
    },
    error: {
      validations: 'Error: Validation Errors Found',
    },
  },
  revenues: {
    cancelCharges: 'Charges have been Cancelled for this Pro'
  }
};
