import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commoditiesPieces'
})
export class CommoditiesPiecesPipe implements PipeTransform {

  transform(commodityPieces: string, hazardous: boolean = false): string {
    const pieces = commodityPieces ?? '';
    const hazmat = hazardous ? 'X' : '';
    return `${pieces}${hazmat}`;
  }
}
