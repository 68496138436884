import {
  CorrectionsAuditorFunctionRoutesModel,
  CorrectionsBillDetailRoutesModel,
  CorrectionsFormRoutesModel,
  CorrectionsPendingRequestRoutesModel,
  CorrectionsPreviewRateRoutesModel,
  CorrectionsRoutesModel,
} from '../models/routes/routes-corrections.model';

const correctionsPreviewRateRoutes: CorrectionsPreviewRateRoutesModel = {
  main: 'preview',
  autoRatePreview: 'auto-rate',
  manualRatePreview: 'manual-rate',
  manualRatePreviewFromAuto: 'manual-rate-auto',
};

const correctionsAuditorFunctionRoutes: CorrectionsAuditorFunctionRoutesModel = {
  auditInfoPending: 'pending-audit-info',
  previewRate: correctionsPreviewRateRoutes,
  form: 'form',
  main: 'auditor',
  pending: 'pending',
  reassign: 'reassign',
  summary: 'summary',
};

const correctionsFormRoutes: CorrectionsFormRoutesModel = {
  main: 'form',
  previewRate: correctionsPreviewRateRoutes,
};

const correctionsPendingRequestRoutes: CorrectionsPendingRequestRoutesModel = {
  main: 'pending-request',
  previewRate: correctionsPreviewRateRoutes
};

const correctionsBillDetailsRoutes: CorrectionsBillDetailRoutesModel = {
  main: 'bill-detail',
  auditInfoHistory: 'history-audit-info',
  auditInfoPending: 'pending-audit-info',
  content: 'content',
  detail: 'detail',
  history: 'history',
  paymentHistory: 'payment-history',
  pending: 'pending',
  pendingRequest: correctionsPendingRequestRoutes,
  search: 'search',
};

export const CorrectionsRoutes: CorrectionsRoutesModel = {
  main: 'corrections',
  deny: 'deny',
  billDetail: correctionsBillDetailsRoutes,
  form: correctionsFormRoutes,
  auditorFunction: correctionsAuditorFunctionRoutes,
};
