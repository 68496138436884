import { Pipe, PipeTransform } from '@angular/core';
import { SupplementalReference } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'supplementalReference'
})
export class SupplementalReferencePipe implements PipeTransform {

  transform(suppType: SupplementalReference | undefined): string {
    if (!suppType) return '';
    return `${suppType.suppRefTypeCd} ${suppType.refValue}`;
  }
}
