import { createSelector } from '@ngrx/store';
import { SideNavReducer, SideNavStateModel } from '../reducer/side-nav.reducer';

const selector = createSelector(SideNavReducer.sideNavFeatureSelector, (state) => state);

const getSidenavOpenSection = createSelector(selector, (state: SideNavStateModel) => state.sidenavOpenSection);

export const SideNavSelector = {
  getSidenavOpenSection,
};
