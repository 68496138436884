import { AbstractControl } from "@angular/forms";

export class CorrectionsDiscountAmountFormModel {
  delete: AbstractControl;
  percentage: AbstractControl;
  description: AbstractControl;
  amount: AbstractControl;
  sequenceNbr: AbstractControl;
};

export class CorrectionsDiscountAmountModel {
  delete: boolean;
  sequenceNbr?: string;
  percentage: number | string;
  description: string;
  amount: number | string;
};
