import { createAction, props } from "@ngrx/store";
import { AccessorialModel } from "@shared/models/shared/common-data/accessorials.model";
import { ErrorModel } from "@shared/models/shared/error.model";

export abstract class AccessorialsActions {
    static getAccessorials = createAction(
        '[AccessorialsActions] Get Accessorials',
        props<{ pickupDate: string }>()
    );
    static getAccessorialsSuccess = createAction(
        '[AccessorialsActions] Get Accessorials Success',
        props<{ accessorials: AccessorialModel[] }>()
    );
    static getAccessorialsFail = createAction(
        '[AccessorialsActions] Get Accessorials Fail',
        props<{ errors: ErrorModel[] }>()
    );

}
