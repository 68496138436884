import { createAction, props } from '@ngrx/store';
import { BillClassEnum } from '@shared/enums/common-data/bill-class.enum';
import { RequestActionTypeCd } from '@shared/enums/common-data/request-action-type.enum';
import { CorrectionsRequestFormTypeEnum } from '@shared/enums/corrections/corrections-form/corrections-form.enum';
import { CorrectionsBillChargesGetModel, CorrectionsBillChargesModel } from '@shared/models/corrections/bill-payment/corrections-bill-charges.model';
import {
  CorrectionsBilltoRespModel,
  CorrectionsCreditStatusRespModel,
  CorrectionsDetailModel
} from '@shared/models/corrections/corrections-detail.model';
import {
  CorrectionsFormCreateRequestResponseModel,
  CorrectionsFormValueModel,
  CorrectionsTdcFormValueModel
} from '@shared/models/corrections/corrections-form/corrections-form.model';
import { CorrectedRevenueModel, CorrectionsRevenuesModel } from '@shared/models/corrections/corrections-form/revenues/corrections-revenues.model';
import { CorrectionsListMetadataModel } from '@shared/models/corrections/corrections-list-metadata.model';
import { CorrectionsRequestSummaryModel } from '@shared/models/corrections/corrections-request-queue-summaries.model';
import { CorrectionsPreviewRatesModel } from '@shared/models/corrections/preview-rates/corrections-preview-rates.model';
import { ApiResponseModel } from '@shared/models/shared/api-response.model';
import { ErrorModel } from '@shared/models/shared/error.model';
import { EditMetadataModel } from '@shared/models/shared/metadata.model';
import { PendingRequestStoreModel } from '@shared/models/shared/store/pending-request-store.model';
import { ShipmentDetailRespModel } from '@shared/models/shipment/shipment-detail.model';
import {
  CorrectionsRequestAndHistoryListType,
  CorrectionsRequestAndHistoryType
} from '@shared/types/corrections/corrections-request-and-history.types';
import { ListCorrectionRequestsAndHistoryQuery } from '@xpo-ltl-2.0/sdk-billcorrection';
import { BillTypeCd } from '@xpo-ltl/sdk-common';
import { GetCustomerSicOverrideResp } from '@xpo-ltl/sdk-dataservices';

export abstract class CorrectionsActions {
  static clearProNbr = createAction('[CorrectionsActions] Clear Pro Number');

  static setProNbr = createAction('[CorrectionsActions] Set Pro Number', props<{ proNbr: string }>());
  
  static setCurrentRequestFormLockedInd = createAction('[CorrectionsActions] Set Current Request Form Locked Value', props<{ lockedInd: boolean }>());
  
  static setRequestFormType = createAction(
    '[CorrectionsActions] Set Request Form Type',
    props<{ requestFormType: CorrectionsRequestFormTypeEnum }>()
  );
  static setDisableTdcOptionRequestForm = createAction(
    '[CorrectionsActions] Set Disable Tdc Option for Request Form',
    props<{ optionDisabled: boolean }>()
  );

  // GETS *************************************************************************************************************************
  static getCustomerSicOverride = createAction(
    '[CorrectionsActions] Get Customer SIC Override',
    props<{ customerNbr: number }>()
  );
  static getCustomerSicOverrideSuccess = createAction(
    '[CorrectionsActions] Get Customer SIC Override Success',
    props<{ customerSicOverride: GetCustomerSicOverrideResp }>()
  );
  static getCustomerSicOverrideFail = createAction(
    '[CorrectionsActions] Get Customer SIC Override Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getDetails = createAction(
    '[CorrectionsActions] Get Corrections Detail',
    props<{ proNbr: string, errorActionFn: Function }>()
  );
  static getDetailsSuccess = createAction(
    '[CorrectionsActions] Get Corrections Detail Success',
    props<{ correctionsDetail: CorrectionsDetailModel }>()
  );
  static getDetailsFail = createAction(
    '[CorrectionsActions] Get Corrections Detail Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getPendingCorrectionDetails = createAction(
    '[CorrectionsActions] Get Corrections Pending Detail',
    props<{ pendingRequestStore: PendingRequestStoreModel, errorActionFn: Function }>()
  );
  static getPendingCorrectionDetailsSuccess = createAction(
    '[CorrectionsActions] Get Corrections Pending Detail Success',
    props<{
      originalDetail: CorrectionsDetailModel | undefined;
      correctedDetail: CorrectionsDetailModel | undefined;
      correctedFormFields: CorrectionsFormValueModel | undefined;
    }>()
  );
  static getPendingCorrectionDetailsFail = createAction(
    '[CorrectionsActions] Get Corrections Pending Detail Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getBillCharges = createAction(
    '[CorrectionsActions] Get Bill Charges',
    props<{ billChargesGetVariables: CorrectionsBillChargesGetModel }>()
  );

  static getBillChargesSuccess = createAction(
    '[CorrectionsActions] Get Bill Charges Success',
    props<{
      billCharges: CorrectionsBillChargesModel;
    }>()
  );
  static getBillChargesFail = createAction(
    '[CorrectionsActions] Get Bill Charges Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getAutoRatePreview = createAction(
    '[CorrectionsActions] Get Auto Rate Preview',
    props<{ proNbr: string; shipInstId: number; correctionsForm: CorrectionsFormValueModel }>()
  );
  static getAutoRatePreviewSuccess = createAction(
    '[CorrectionsActions] Get Auto Rate Preview Success',
    props<{ autoRatesPreview: CorrectionsPreviewRatesModel | undefined }>()
  );
  static getAutoRatePreviewFail = createAction(
    '[CorrectionsActions] Get Auto Rate Preview Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getManualRatePreview = createAction(
    '[CorrectionsActions] Get Manual Rate Preview',
    props<{ proNbr: string; shipInstId: number; correctionsForm: CorrectionsFormValueModel }>()
  );
  static getManualRatePreviewSuccess = createAction(
    '[CorrectionsActions] Get Manual Rate Preview Success',
    props<{ manualRatesPreview: CorrectionsPreviewRatesModel }>()
  );
  static getManualRatePreviewFail = createAction(
    '[CorrectionsActions] Get Manual Rate Preview Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getCorrectedRevenues = createAction(
    '[CorrectionsActions] Get Corrected Revenue Values',
    props<{ correctedRevenueModel: CorrectedRevenueModel }>()
  );
  static getCorrectedRevenuesSuccess = createAction(
    '[CorrectionsActions] Get Corrected Revenue Values Success',
    props<{ revenuesResp: CorrectionsRevenuesModel | undefined }>()
  );
  static getCorrectedRevenuesFail = createAction(
    '[CorrectionsActions] Get Corrected Revenue Values Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getListCorrectionsEditMetaData = createAction(
    '[CorrectionsActions] Get List Corrections Edit Meta Data',
    props<{ metadataParams: EditMetadataModel }>()
  );
  static getListCorrectionsEditMetaDataSuccess = createAction(
    '[CorrectionsActions] Get List Corrections Edit Meta Data Success',
    props<{ correctionsEditMetadata: CorrectionsListMetadataModel }>()
  );
  static getListCorrectionsEditMetaDataFail = createAction(
    '[CorrectionsActions] Get List Corrections Edit Meta Data Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getListCorrectionsAuditMetaData = createAction(
    '[CorrectionsActions] Get List Corrections Audit Meta Data',
    props<{ loadingOverlayEnabled: boolean }>()
  );
  static getListCorrectionsAuditMetaDataSuccess = createAction(
    '[CorrectionsActions] Get List Corrections Audit Meta Data Success',
    props<{ correctionsAuditMetadata: CorrectionsListMetadataModel }>()
  );
  static getListCorrectionsAuditMetaDataFail = createAction(
    '[CorrectionsActions] Get List Corrections Audit Meta Data Fail',
    props<{ errors: ErrorModel[] }>()
  );
  
  static getListCorrectionsReassignMetaData = createAction(
    '[CorrectionsActions] Get List Corrections Reassign Meta Data',
    props<{ loadingOverlayEnabled: boolean }>()
  );
  static getListCorrectionsReassignMetaDataSuccess = createAction(
    '[CorrectionsActions] Get List Corrections Reassign Meta Data Success',
    props<{ correctionsReassignMetadata: CorrectionsListMetadataModel }>()
  );
  static getListCorrectionsReassignMetaDataFail = createAction(
    '[CorrectionsActions] Get List Corrections Reassign Meta Data Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getRequestAndHistory = createAction(
    '[CorrectionsActions] Get Corrections Request and History',
    props<{ queryParams: ListCorrectionRequestsAndHistoryQuery }>()
  );
  static getRequestAndHistorySuccess = createAction(
    '[CorrectionsActions] Get Corrections Request and History Success',
    props<{ correctionsRequestAndHistory: ApiResponseModel<CorrectionsRequestAndHistoryListType> }>()
  );
  static getRequestAndHistoryFail = createAction(
    '[CorrectionsActions] Get Corrections Request and History Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getRequestAuditInfo = createAction(
    '[CorrectionsActions] Get Corrections Request Audit Info by Id',
    props<{ requestId: number; includeHistoryInd: boolean }>()
  );
  static getRequestAuditInfoSuccess = createAction(
    '[CorrectionsActions] Get Corrections Request Audit Info Success by Id',
    props<{ correctionsRequestAndHistory: ApiResponseModel<CorrectionsRequestAndHistoryType> }>()
  );
  static getRequestAuditInfoFail = createAction(
    '[CorrectionsActions] Get Corrections Request Audit Info Fail by Id',
    props<{ errors: ErrorModel[] }>()
  );

  static getRatingInformation = createAction(
    '[CorrectionsActions] Get Rating Information',
    props<{ shipmentInstId: number }>()
  );
  static getRatingInformationSuccess = createAction(
    '[CorrectionsActions] Get Rating Information Success',
    props<{ ratingInformationResp: ShipmentDetailRespModel }>()
  );
  static getRatingInformationFail = createAction(
    '[CorrectionsActions] Get Rating Information Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getAssociatedBillTo = createAction(
    '[CorrectionsActions] Get The Associated Bill To',
    props<{ customerNumber: number; billCd: BillTypeCd; madCode: string }>()
  );
  static getAssociatedBillToSuccess = createAction(
    '[CorrectionsActions] Get The Associated Bill To Success',
    props<{ associatedBillTo: CorrectionsBilltoRespModel | undefined }>()
  );
  static getAssociatedBillToFail = createAction(
    '[CorrectionsActions] Get The Associated Bill To Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getCustomerCreditStatus = createAction(
    '[CorrectionsActions] Get The Credit Status of the Customer',
    props<{ customerNumber: number }>()
  );
  static getCustomerCreditStatusSuccess = createAction(
    '[CorrectionsActions] Get The Credit Status of the Customer Success',
    props<{ creditStatus: CorrectionsCreditStatusRespModel | undefined }>()
  );
  static getCustomerCreditStatusFail = createAction(
    '[CorrectionsActions] Get The Credit Status of the Customer Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getRequestQueueSummaries = createAction(
    '[CorrectionsActions] Get Corrections Request Queue Summaries',
    props<{ loadingOverlayEnabled: boolean }>()
  );
  static getRequestQueueSummariesSuccess = createAction(
    '[CorrectionsActions] Get Corrections Request Queue Summaries Success',
    props<{ correctionsRequestQueueSummaries: CorrectionsRequestSummaryModel }>()
  );
  static getRequestQueueSummariesFail = createAction(
    '[CorrectionsActions] Get Corrections Request Queue Summaries Fail',
    props<{ errors: ErrorModel[] }>()
  );

  // ***************************************************************************************************************

  // DELETES *******************************************************************************************************
  static deleteCorrections = createAction(
    '[CorrectionsActions] Delete Corrections Request by id',
    props<{ requestIds: number[] }>()
  );
  static deleteCorrectionsSuccess = createAction('[CorrectionsActions] Delete Corrections Request by id Success');
  static deleteCorrectionsFail = createAction(
    '[CorrectionsActions] Delete Corrections Request by id Fail',
    props<{ errors: ErrorModel[] }>()
  );

  // SETTERS ********************************************************************************************************
  static setCurrentRequestForm = createAction(
    '[CorrectionsActions] Set Current Request Form Value',
    props<{ currentRequestForm: CorrectionsFormValueModel }>()
  );
  static setOriginalRequestForm = createAction(
    '[CorrectionsActions] Set Original Request Form Value',
    props<{ originalRequestForm: CorrectionsFormValueModel }>()
  );

  // SUBMITS ********************************************************************************************************
  static submitRequest = createAction(
    '[CorrectionsActions] Submit Request',
    props<{ correctionsForm: CorrectionsFormValueModel, originalForm: CorrectionsFormValueModel }>()
  );
  static submitRequestSuccess = createAction(
    '[CorrectionsActions] Submit Request Success',
    props<{ response: CorrectionsFormCreateRequestResponseModel }>()
  );
  static submitRequestFail = createAction(
    '[CorrectionsActions] Submit Request Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static submitTdcRequest = createAction(
    '[CorrectionsActions] Submit TDC Request',
    props<{ tdcCorrectionsForm: CorrectionsTdcFormValueModel }>()
  );
  static submitTdcRequestSuccess = createAction(
    '[CorrectionsActions] Submit TDC Request Success',
    props<{ response: CorrectionsFormCreateRequestResponseModel }>()
  );
  static submitTdcRequestFail = createAction(
    '[CorrectionsActions] Submit TDC Request Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static submitAuditorRequest = createAction(
    '[CorrectionsActions] Submit Auditor Request',
    props<{ correctionsForm: CorrectionsFormValueModel, originalForm: CorrectionsFormValueModel, requestActionTypeCd: RequestActionTypeCd; }>()
  );
  static submitAuditorRequestSuccess = createAction(
    '[CorrectionsActions] Submit Auditor Request Success',
    props<{ response: CorrectionsFormCreateRequestResponseModel }>()
  );
  static submitAuditorRequestFail = createAction(
    '[CorrectionsActions] Submit Auditor Request Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static submitTdcAuditorRequest = createAction(
    '[CorrectionsActions] Submit TDC Auditor Request',
    props<{ tdcCorrectionsForm: CorrectionsTdcFormValueModel; requestActionTypeCd: RequestActionTypeCd }>()
  );
  static submitTdcAuditorRequestSuccess = createAction(
    '[CorrectionsActions] Submit TDC Auditor Request Success',
    props<{ response: CorrectionsFormCreateRequestResponseModel }>()
  );
  static submitTdcAuditorRequestFail = createAction(
    '[CorrectionsActions] Submit TDC Auditor Request Fail',
    props<{ errors: ErrorModel[] }>()
  );

  // ***************************************************************************************************************

  // UPDATES ********************************************************************************************************
  static updateUnlockCorrectionRequestStatus =  createAction(
    '[CorrectionsActions] Update Unlock Corrections Request Status',
    props<{
      correctionRequestInstIds: number[];
    }>()
  );
  static updateUnlockCorrectionRequestStatusSuccess =  createAction(
    '[CorrectionsActions] Update Unlock Corrections Request Status Success'
  );
  static updateUnlockCorrectionRequestStatusFail =  createAction(
    '[CorrectionsActions] Update Unlock Corrections Request Status Fail',
    props<{errors: ErrorModel[]}>()
  );

  static updateQueueForCorrectionRequests = createAction(
    '[CorrectionsActions] Update Queue for Correction Requests',
    props<{correctionRequestInstIds: number[], queueName: string
    }>()
  );
  static updateQueueForCorrectionRequestsSuccess = createAction(
    '[CorrectionsActions] Update Queue for Correction Requests Success',
    props<{ response: string }>()
  );
  static updateQueueForCorrectionRequestsFail = createAction(
    '[CorrectionsActions] Update Queue for Correction Requests Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static resubmitRequest = createAction(
    '[CorrectionsActions] Resubmit Request',
    props<{ correctionsForm: CorrectionsFormValueModel, originalForm: CorrectionsFormValueModel }>()
  );
  static resubmitRequestSuccess = createAction(
    '[CorrectionsActions] Resubmit Request Success',
    props<{ response: CorrectionsFormCreateRequestResponseModel }>()
  );
  static resubmitRequestFail = createAction(
    '[CorrectionsActions] Resubmit Request Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static resubmitTdcRequest = createAction(
    '[CorrectionsActions] Resubmit TDC Request',
    props<{ tdcCorrectionsForm: CorrectionsTdcFormValueModel }>()
  );
  static resubmitTdcRequestSuccess = createAction(
    '[CorrectionsActions] Resubmit TDC Request Success',
    props<{ response: CorrectionsFormCreateRequestResponseModel }>()
  );
  static resubmitTdcRequestFail = createAction(
    '[CorrectionsActions] Resubmit TDC Request Fail',
    props<{ errors: ErrorModel[] }>()
  );

  // ***************************************************************************************************************
}
