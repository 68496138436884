import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserPreferenceReducer } from './reducer/user-preference.reducer';
import { UserPreferenceEffects } from './effects/user-preference.effects';
import { UserPreferenceHandlerService } from './handlers/user-preference-handler.service';
import { UserPreferenceApiService } from '@xpo-ltl/sdk-userpreference';

@NgModule({
  imports: [
    StoreModule.forFeature(UserPreferenceReducer.userPreferenceFeatureKey, UserPreferenceReducer.reducer),
    EffectsModule.forFeature([UserPreferenceEffects]),
  ],
  providers: [UserPreferenceHandlerService, UserPreferenceApiService],
})
export class UserPreferenceReduxModule {}
