import { AbstractControl } from "@angular/forms";
import { CorrectionsAccessorialsFormEnum } from "@shared/enums/corrections/corrections-form/accessorials/corrections-accessorials-form.enum";

export class CorrectionsAccessorialsFormModel {
  sequenceNbr: AbstractControl;
  id: AbstractControl;
  delete: AbstractControl;
  code: AbstractControl;
  description: AbstractControl;
  rate: AbstractControl;
  amount: AbstractControl;
  minimumChargeInd: AbstractControl;
  markedAction: AbstractControl;
};

export class CorrectionsAccessorialsModel {
  [CorrectionsAccessorialsFormEnum.sequenceNbr]: string | null;
  [CorrectionsAccessorialsFormEnum.id]?: string;
  [CorrectionsAccessorialsFormEnum.delete]: boolean;
  [CorrectionsAccessorialsFormEnum.code]: string;
  [CorrectionsAccessorialsFormEnum.description]: string;
  [CorrectionsAccessorialsFormEnum.rate]: string | number;
  [CorrectionsAccessorialsFormEnum.amount]: string | number;
  [CorrectionsAccessorialsFormEnum.minimumChargeInd]: boolean;
  [CorrectionsAccessorialsFormEnum.markedAction]?: string;
};

export class CorrectionAccessorialsFormGroupModel {
  [CorrectionsAccessorialsFormEnum.sequenceNbr]: any;
  [CorrectionsAccessorialsFormEnum.id]: any;
  [CorrectionsAccessorialsFormEnum.delete]: any;
  [CorrectionsAccessorialsFormEnum.code]: any;
  [CorrectionsAccessorialsFormEnum.description]: any;
  [CorrectionsAccessorialsFormEnum.rate]: any;
  [CorrectionsAccessorialsFormEnum.amount]: any;
  [CorrectionsAccessorialsFormEnum.minimumChargeInd]: any;
  [CorrectionsAccessorialsFormEnum.markedAction]: any;
};
